import tableFields from "./fields.json";
import ListPage from "../../../components/shared/ListPage/ListPage";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useAPI from "../../../api/api";
import DeleteModal from "../../../components/shared/Dialogs/DeleteDialog";
import DeleteDialog from "../../../components/shared/Dialogs/DeleteDialog";
import { toast } from "react-toastify";

const AllContracts = () => {
    const [formFields, setFormFields] = useState(tableFields);
    const api = useAPI();
    const buttons = [
        {
            label: "Potvrda ugovora",
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/contracts/confirm`;
                },
            },
        },
        {
            label: "Istekli ugovori",
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/contracts/expired`;
                },
            },
        },
        {
            label: "Predistek ugovori",
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/contracts/to-be-expired`;
                },
            },
        },
        {
            label: "Izmene na ugovorima",
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/contracts/changes`;
                },
            },
        },
    ];
    const [doesRefetch, setDoesRefetch] = useState(false);
    const { data: is_consultant } = useQuery({
        queryKey: ["is_consultant"],
        queryFn: async () => {
            return await api.get(`/admin/users/is-consultant`, "CONTRACTS_LIST").then((res) => {
                if (res) {
                    return res?.payload?.is_consultant;
                }
            });
        },
    });

    const handleVisibleButtons = (is_consultant, buttons) => {
        if (is_consultant) {
            return buttons.filter((button) => button.label === "Istekli ugovori" || button.label === "Predistek ugovori");
        }
        return buttons;
    };

    const [openModal, setOpenModal] = useState({
        show: false,
        id: null,
        name: null,
        data: [],
        type: "",
    });

    const actions = {
        edit: {
            type: "edit",
            display: true,
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/portal-customers/contracts/${rowData.client_id}/${rowData.id}`;
                },
            },
        },
        delete: {
            position: 4,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    api.get(`/admin/contracts/list/message/${rowData?.id}`, "CONTRACTS_DELETE").then((res) => {
                        setOpenModal({ data: res?.payload, show: true, id: rowData.id, name: rowData.name, type: "delete" });
                    });
                },
            },
        },
    };

    let screen_code = "PORTAL_CONTRACTS_MAIN_LIST";

    return (
        <>
            <ListPage
                customActions={actions}
                additionalButtons={handleVisibleButtons(is_consultant, buttons)}
                showNewButton={false}
                formFields={formFields}
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
                title={`Ugovori`}
                screen_code={screen_code}
                apiUrl={`admin/contracts/main/list`}
                deleteUrl={`admin/contracts/main/confirm`}
                columnFields={formFields}
            />
            {openModal?.type === "delete" && (
                <DeleteDialog
                    title={openModal?.portal_customer_line ?? "Obaveštenje"}
                    screen_code={"CLIENTS_DELETE"}
                    handleConfirm={() => {
                        api.delete(`admin/contracts/list/confirm/${openModal.id}`, "CONTRACTS_DELETE")
                            .then((res) => {
                                setOpenModal({ ...openModal, show: false });
                                setDoesRefetch(!doesRefetch);
                                toast.success("Ugovor uspešno obrisan");
                            })
                            .catch((err) => {
                                toast.error("Greška prilikom brisanja ugovora");
                            });
                    }}
                    description={openModal?.data?.main_line ?? ""}
                    openDeleteDialog={openModal}
                    setOpenDeleteDialog={setOpenModal}
                    nameOfButton={openModal?.data?.main_checkbox ?? "Obriši"}
                />
            )}
        </>
    );
};

export default AllContracts;
