import NoteBox from "./shared/NoteBox/NoteBox";
import PageWrapper from "./shared/Layout/PageWrapper/PageWrapper";

export const Dashboard = () => {
    return (
        <PageWrapper title="Dashboard" back={false}>
            <NoteBox>
                <center>Dobrodošli na Scoring.rs administraciju.</center>
            </NoteBox>
        </PageWrapper>
    );
};
