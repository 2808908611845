import Form from "../../../../components/shared/Form/Form";
import useAPI from "../../../../api/api";
import { useEffect, useState } from "react";
import tblFields from "./tblFields.json";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

const ScoringPackages = ({ screen_code, client_id, reloadTabs, acc_id, contract_id }) => {
    const [params] = useSearchParams();
    const confirm = params.get("confirm");
    const api = useAPI();
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
    const navigate = useNavigate();
    const init = {
        id: null,
        user_id: acc_id,
        contracts_id: contract_id,
    };
    const [data, setData] = useState(init);
    const [basicDataTemp, setBasicDataTemp] = useState(tblFields);

    const [scoringPackages, setScoringPackages] = useState([]);
    const [scoringPackagesItems, setScoringPackagesItems] = useState([]);

    const updateNewFieldsInDetails = (data, isNew) => {
        data.map((item, i) => {
            if (isNew) {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = true;
                }
            } else {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = false;
                }
            }
        });
        setBasicDataTemp([...data]);
    };
    const handleSubmit = (data) => {
        setIsLoadingOnSubmit(true);
        let oldId = data.id;
        if (data?.scoring_packages?.length > 0 && data?.scoring_packages_items?.length > 0) {
            api.post(`admin/contracts/accounts/scoring-packages`, data, data?.id ? "ACCOUNTS_SCORING_PACKAGES_UPDATE" : "ACCOUNTS_SCORING_PACKAGES_NEW")
                .then((response) => {
                    toast.success("Uspešno");
                    setData(response?.payload);
                    reloadTabs();
                    if (confirm === "true") {
                        navigate(`/accounts/${client_id}/${contract_id}/${response?.payload?.id}?tab=limits&confirm=true`, { replace: true });
                    } else {
                        navigate(`/accounts/${client_id}/${contract_id}/${acc_id}?tab=limits`, { replace: true });
                    }

                    setIsLoadingOnSubmit(false);
                })
                .catch((error) => {
                    console.warn(error);
                    toast.warn(error?.response?.data?.message ?? "Greška");

                    setIsLoadingOnSubmit(false);
                });
        } else {
            toast.warning("Morate izabrati bar jedan paket i bar jednu funkciju");
            setIsLoadingOnSubmit(false);
        }
    };

    const formatFormFields = (data) => {
        let arr = [];
        data.map((item) => {
            if (item?.prop_name === "scoring_packages") {
                item.usePropName = false;
                item.fillFromApi = item.fillFromApi + "/scoring_packages?contracts_id=" + contract_id;
            }
            if (item?.prop_name === "scoring_packages_items") {
                item.usePropName = false;
                item.fillFromApi = item.fillFromApi + "/scoring_packages_items?contracts_id=" + contract_id;
            }
        });
        setBasicDataTemp([...data]);
    };

    const handleData = () => {
        api.get(`admin/contracts/accounts/scoring-packages/new?user_id=${acc_id}`, "ACCOUNTS_SCORING_PACKAGES_DETAILS")
            .then(async (response) => {
                let temp_data = response?.payload;
                if (!temp_data?.contracts_id) {
                    temp_data.contracts_id = contract_id;
                }
                if (!temp_data?.user_id) {
                    temp_data.user_id = acc_id;
                }

                setData(temp_data);
                updateNewFieldsInDetails(tblFields, temp_data);
            })
            .catch((error) => console.warn(error));
    };

    useEffect(() => {
        handleData();
        formatFormFields(basicDataTemp);
    }, []);

    const { data: scoring_packages } = useQuery(
        ["scoring_packages"],
        async () => {
            return await api.get(`admin/contracts/accounts/scoring-packages/ddl/scoring_packages?contracts_id=${contract_id}`, "ACCOUNTS_SCORING_PACKAGES_DETAILS").then((res) => {
                setScoringPackages(res?.payload);
                return res?.payload;
            });
        },
        { refetchOnWindowFocus: false },
    );

    const { data: scoring_packages_items } = useQuery(
        ["scoring_packages_items"],
        async () => {
            return await api.get(`admin/contracts/accounts/scoring-packages/ddl/scoring_packages_items?contracts_id=${contract_id}`, "ACCOUNTS_SCORING_PACKAGES_DETAILS").then((res) => {
                setScoringPackagesItems(res?.payload);
                return res?.payload;
            });
        },
        { refetchOnWindowFocus: false },
    );

    // const setInitSelectedScoringPackages = async (data) => {
    //     let temp = [];
    //     scoringPackages?.map((item) => {
    //         if (data?.includes(item.id)) {
    //             temp?.push({ ...item, selected: true });
    //         } else {
    //             temp?.push({ ...item, selected: false });
    //         }
    //     });
    //     setScoringPackages([...temp]);
    // };
    //
    // const setInitSelectedScoringPackagesItems = (data) => {
    //     let temp = [];
    //     temp = scoringPackagesItems.map((item) => {
    //         if (data?.includes(item.id)) {
    //             item.selected = true;
    //         } else {
    //             item.selected = false;
    //         }
    //     });
    //     setScoringPackagesItems([...temp]);
    // };

    const validateData = (data, field) => {
        let ret = data;
        handleOnChange(field, data, scoringPackages, scoringPackagesItems);

        switch (field) {
            case "new":
                updateNewFieldsInDetails(tblFields, ret);
                return ret;
            default:
                return ret;
        }
    };

    const handleOnChange = async (field, data, scoring_packages, scoring_packages_items) => {
        //selektovani id-jevi od scoring_packages
        const scoring_packages_ids = data?.scoring_packages?.map((id) => id);

        //selektovani id-jevi od scoring_packages_items
        const scoring_packages_items_ids = data?.scoring_packages_items?.map((id) => id);
        //brisemo duplikate iz scoring_packages_items_ids
        let scoring_packages_items_ids_filtered = [...new Set(scoring_packages_items_ids)];

        //kad se selektuje neki scoring_package, dodaj njegove scoring_package_items u data sa setData, tako sto ce se uzeti selektovane id-jeve iz scoring_packages_ids i naci id-jeve funkcija u scoring_packages_items.data.packages
        if (field === "scoring_packages") {
            let temp = [];
            scoring_packages_ids?.map((id) => {
                scoring_packages_items?.map((item) => {
                    item?.data?.packages?.map((pac) => {
                        if (pac?.id === id) {
                            temp?.push(item?.id);
                        }
                    });
                });
            });

            const remove_temp_duplicates = [...new Set(temp)];

            setData({ ...data, scoring_packages_items: [...remove_temp_duplicates] });
        }

        if (field === "scoring_packages_items") {
            //imam sve id-jeve selektovanih f-ja
            //pitaj da li se barem jedna sadrzi u nekom scoring_packages.data.functions
            //ako se nijedna ne sadrzi, obrisi paket
            //ako se bar jedna sadrzi, ne radi nista

            const convertPacArrToIds = (arr) => {
                let temp = [];
                arr?.map((item) => {
                    let ids = [];
                    item?.data?.functions?.map((fun) => {
                        ids.push(fun?.id);
                    });
                    temp.push({ item, functions: ids });
                });
                return temp;
            };

            const convertedPacArr = convertPacArrToIds(scoring_packages);

            //sad imamo niz s kojim mozemo da radimo
            //proveravamo da li se bar jedna funkcija iz selektovanih nalazi u nekom paketu
            let temp = [];
            scoring_packages_items_ids_filtered?.map((id) => {
                convertedPacArr?.map((item) => {
                    if (item?.functions?.includes(id) && data?.scoring_packages?.includes(item?.item?.id)) {
                        temp.push(item?.item?.id);
                    }
                });
            });

            const removed_duplicates = [...new Set(temp)];

            setData({ ...data, scoring_packages: [...removed_duplicates] });
        }
    };

    const {
        data: contract_changes,
        isLoading,
        isSuccess,
    } = useQuery(
        ["accountScoringPackagesChanges", contract_id, acc_id, isLoadingOnSubmit],
        async () => {
            return await api.get(`admin/contracts/accounts/scoring-packages/edited-fields/${contract_id}?account_id=${acc_id}`, "CONTRACTS_DETAILS").then((res) => res?.payload);
        },
        {},
    );

    const formatFields = (data) => {
        const objectKeys = Object.keys(data);

        let temp_data = basicDataTemp?.map((item) => {
            if (objectKeys?.includes(item?.prop_name)) {
                return {
                    ...item,
                    description: `Za ovo polje je zatražena izmena. Zatražena nova vrednost: ${data[item?.prop_name]}`,
                };
            } else {
                return item;
            }
        });
        setBasicDataTemp(temp_data);
    };

    useEffect(() => {
        if (!isLoading && isSuccess) {
            const timeout = setTimeout(() => {
                formatFields(contract_changes);
            }, 200);
            return () => clearTimeout(timeout);
        }
    }, [isLoading]);

    return (
        <Form
            screen_code={"ACCOUNTS_SCORING_PACKAGES_DETAILS"}
            formFields={basicDataTemp}
            initialData={data}
            client_id={contract_id}
            onSubmit={handleSubmit}
            validateData={validateData}
            loading={isLoadingOnSubmit}
        />
    );
};

export default ScoringPackages;
