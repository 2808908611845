import { useTableCellActions } from "../../hooks/useTableCellActions";
import { useCellSubmit } from "../../hooks/useCellSubmit";
import useAPI from "../../api/api";
import { useEffect, useState } from "react";
import ListPage from "../../components/shared/ListPage/ListPage";
import { useMutation, useQuery } from "react-query";
import fields from "./fields.json";
import formFields from "./formFields.json";
import Box from "@mui/system/Box";
import { InputCheckbox, InputSelect } from "../../components/shared/Form/FormInputs/FormInputs";
import { useSearchParams } from "react-router-dom";
import Form from "../../components/shared/Form/Form";
import { toast } from "react-toastify";
import DeleteDialog from "../../components/shared/Dialogs/DeleteDialog";

export const Translations = () => {
    const [doesRefetch, setDoesRefetch] = useState(false);
    const [params, setSearchParams] = useSearchParams();
    const [selectedIds, setSelectedIds] = useState([]);
    const [refetchIds, setRefetchIds] = useState([]);

    const url_system = params.get("system");

    const [selectedSystem, setSelectedSystem] = useState(url_system ?? "static_pages_portal");
    const api = useAPI();

    let screen_code = "TRANSLATIONS_LIST";

    const getTableCellFormData = async ({ cell_data, selected }) => {
        return await api.get(`${cell_data?.api_path}/${cell_data?.queryString}`, screen_code).then((res) => {
            return res?.payload;
        });
    };

    const { customTableCellActions } = useTableCellActions({ clickAction: "edit", click: true, doubleClick: false, doubleClickAction: "none" });

    const submitCell = useCellSubmit();

    const [translateModal, setTranslateModal] = useState({
        show: false,
        id: "",
        data: [],
        main_line: "",
    });

    const { mutate: checkKey, data: list_data } = useMutation({
        mutationKey: ["translationsUpdateModal"],
        mutationFn: async ({ data, cell_data }) => {
            return await api
                .post(
                    `/admin/translations/crud/translate/message`,
                    {
                        ...data,
                    },
                    screen_code
                )
                .then((res) => {
                    if (res?.payload && res?.payload?.list?.length > 0) {
                        setTranslateModal({
                            show: true,
                            id: data?.key,
                            lang: cell_data?.value?.lang,
                            selected_id: data?.[data?.lang + "_id"],
                            value: cell_data?.value?.value,
                            data: res?.payload?.list,
                            main_line: res?.payload?.main_line,
                            main_checkbox: res?.payload?.main_checkbox,
                        });

                        let ids = (res?.payload?.list ?? [])?.map((item) => item?.id);
                        setSelectedIds([...ids, data?.[data?.lang + "_id"]]);
                        return res?.payload;
                    } else {
                        submitCell(cell_data?.api_url, cell_data?.api_method, cell_data?.value, setDoesRefetch, "TRANSLATIONS_POST");
                        setSelected({
                            row: null,
                            column: null,
                        });
                    }
                });
        },
    });

    const onCellSubmit = (value, row, setSelected, api_url, api_method) => {
        let cell_data = {
            value,
            row,
            setSelected,
            api_url,
            api_method,
        };

        if (cell_data?.value?.[cell_data?.value?.lang + "_id"]) {
            !translateModal?.show && checkKey({ data: value, cell_data });
        } else {
            submitCell(cell_data?.api_url, cell_data?.api_method, cell_data?.value, setDoesRefetch, "TRANSLATIONS_POST");
        }
    };

    const { data: system } = useQuery(["system"], async () => {
        return await api.get(`admin/translations/crud/ddl/system`, screen_code).then((res) => {
            return res?.payload;
        });
    });

    const { data: columns, isFetching } = useQuery(
        ["tableColumns"],
        async () => {
            return await api.get(`admin/translations/crud/table/columns`, screen_code).then((res) => res?.payload);
        },
        { refetchOnWindowFocus: false }
    );

    const [data, setData] = useState(null);

    const { data: form } = useQuery(
        ["formColumns"],
        async () => {
            return await api.get(`admin/translations/crud/new`, screen_code).then((res) => {
                setData(res?.payload);
                return res?.payload;
            });
        },
        { refetchOnWindowFocus: false }
    );

    const { mutate: addNew } = useMutation(["addNew"], async (data) => {
        return await api
            .post(
                `admin/translations/crud`,
                {
                    ...data,
                    system: url_system ?? selectedSystem,
                },
                screen_code
            )
            .then((res) => {
                toast.success(`Uspešno sačuvano!`);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message ?? "Došlo je do greške prilikom čuvanja!");
            });
    });

    const { mutate: handleModalTranslate } = useMutation({
        mutationKey: ["mutateTranslate"],
        mutationFn: async () => {
            return await api
                .post(
                    `admin/translations/crud/translate`,
                    {
                        id: [...new Set([...selectedIds, translateModal?.selected_id])]?.filter(Boolean),
                        lang: translateModal?.lang,
                        system: selectedSystem,
                        value: translateModal?.value,
                    },
                    screen_code
                )
                .then((res) => {
                    toast.success(`Uspešno prevedeno!`);
                    setTranslateModal({ show: false });
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.payload?.message ?? "Došlo je do greške prilikom prevođenja!");
                });
        },
    });

    useEffect(() => {
        if (doesRefetch) {
            setDoesRefetch(false);
        } else {
            return;
        }
    }, [doesRefetch]);

    return (
        <>
            {!isFetching && (
                <ListPage
                    modalFormChildren={
                        <Box sx={{ p: 3 }}>
                            <Form
                                formFields={formFields}
                                initialData={data}
                                onSubmit={(data) => {
                                    addNew(data);
                                }}
                                screen_code={screen_code}
                            />
                        </Box>
                    }
                    title={
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>Prevodi</Box>
                            <InputSelect
                                sx={{
                                    width: "fit-content",
                                }}
                                fillFromApi={`admin/translations/crud/ddl/system`}
                                screen_code={screen_code}
                                onChange={({ target: { value } }) => {
                                    if (value) {
                                        setDoesRefetch(true);
                                        setSelectedSystem(value);
                                        setSearchParams({
                                            system: value,
                                        });
                                    }
                                }}
                                options={system}
                                value={selectedSystem}
                            />
                        </Box>
                    }
                    listPageId={`translations`}
                    actionNewButton={`modal`}
                    apiUrl={`admin/translations/crud/list`}
                    screen_code={screen_code}
                    columnFields={columns}
                    formFields={columns}
                    tableCellActions={{
                        actions: customTableCellActions,
                        onChange: () => {},
                        onSubmit: onCellSubmit,
                        getTableCellFormData: getTableCellFormData,
                        refetchIds: refetchIds,
                        setRefetchIds: setRefetchIds,
                    }}
                    system={selectedSystem}
                    doesRefetch={doesRefetch}
                    setDoesRefetch={setDoesRefetch}
                />
            )}
            <DeleteDialog
                deafultDeleteIcon={false}
                color={`success`}
                title={translateModal?.main_line ?? ""}
                screen_code={"TRANSLATIONS_POST"}
                handleConfirm={handleModalTranslate}
                description={translateModal?.main_checkbox ?? ""}
                openDeleteDialog={translateModal}
                setOpenDeleteDialog={setTranslateModal}
                nameOfButton={"Prevedi"}
                buttonDisabled={{
                    enabled: true,
                    condition: selectedIds?.length === 0,
                }}
                children={translateModal?.children}
            >
                <Box sx={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
                    {(translateModal?.data ?? [])?.map(({ id, page, part, key, value }) => {
                        return (
                            <InputCheckbox
                                key={`translateModal-${id}`}
                                label={page}
                                onChange={({ target: { checked } }) => {
                                    if (checked) {
                                        setSelectedIds([...selectedIds, id]);
                                    } else {
                                        setSelectedIds(selectedIds.filter((item) => item !== id));
                                    }
                                }}
                                value={selectedIds.includes(id)}
                                name={key}
                            />
                        );
                    })}
                </Box>
                <InputCheckbox
                    name={`selectAll`}
                    key={`selectAll`}
                    value={selectedIds.length - 1 === (list_data?.list ?? [])?.length}
                    onChange={({ target: { checked } }) => {
                        if (checked) {
                            let ids = (list_data?.list ?? [])?.map((item) => item?.id);
                            let all_ids = [...ids, translateModal?.selected_id];
                            setSelectedIds([...new Set([...selectedIds, ...all_ids])]);
                        } else {
                            setSelectedIds((prev) => {
                                return prev.filter((item) => !(list_data?.list ?? []).map(({ id }) => id).includes(item));
                            });
                        }
                    }}
                    label={`Označi sve`}
                    description={`Ukoliko označite sve, ova izmena će se primeniti na sve navedene strane preko celog sistema.`}
                />
            </DeleteDialog>
        </>
    );
};
