import fields from "./fields.json";
import { useState, useEffect } from "react";
import ListPage from "../../../../components/shared/ListPage/ListPage";
import useAPI from "../../../../api/api";
import { toast } from "react-toastify";
import DeleteModal from "../../../../components/shared/Dialogs/DeleteDialog";
import { useNavigate } from "react-router-dom";

const ChangesOnContracts = () => {
    const [formFields, setFormFields] = useState(fields);
    const api = useAPI();
    const navigate = useNavigate();
    const [doesRefetch, setDoesRefetch] = useState(false);
    let screen_code = "PORTAL_CONTRACTS_EDITS_POST";
    const [openModal, setOpenModal] = useState({ show: false, id: null, name: null, contracts_id: null, contracts_part: null });
    const customActions = {
        delete: {
            clickHandler: {
                fnc: (rowData) => {
                    setOpenModal({ show: true, id: rowData.id, name: rowData.name, contracts_id: rowData.contracts_id, contracts_part: rowData.contracts_part });
                },
            },
        },
        edit: {
            type: "custom",
            display: true,
            position: 1,
          clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/portal-customers/contracts/${rowData?.client_id}/${rowData?.id}`;
                },
            },
            icon: "edit",
            title: "Izmeni",
        },
        preview: {
            type: "custom",
            display: true,
            position: 2,
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/contracts/changes/${rowData?.id}`;
                },
            },
            icon: "visibility",
            title: "Pregled izmena",
        }
    };

    const buttons = [
        {
            label: "Nazad",
            icon: "arrow_back",
            clickHandler: {
                type: "",
                fnc: () => navigate(-1),
            },
        },
    ];

    return (
        <>
            <ListPage
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
                customActions={customActions}
                listPageId="ChangesOnContracts"
                apiUrl="admin/contracts/edits/list"
                title="Izmene na ugovorima"
                columnFields={formFields}
                showNewButton={false}
                additionalButtons={buttons}
                screen_code={"PORTAL_CONTRACTS_EDITS_LIST"}
            />
            <DeleteModal
                description={`Da li ste sigurni da želite da odbijete izmene?`}
                screen_code={screen_code}
                nameOfButton={`Odbij izmene`}
                title={`Odbijanje izmena`}
                deafultDeleteIcon={false}
                openDeleteDialog={{ show: openModal.show, id: openModal.id, name: openModal.name }}
                setOpenDeleteDialog={setOpenModal}
                handleConfirm={() => {
                    api.post(
                        `admin/contracts/edits/decline/all`,
                        {
                            id: openModal.id,
                            contracts_id: openModal.id,
                            contracts_part: openModal.contracts_part,
                        },
                        screen_code
                    )
                        .then((res) => {
                            toast.success("Izmene su uspešno odbijene!");
                            setDoesRefetch(!doesRefetch);
                            setOpenModal({ show: false, id: null, name: null });
                        })
                        .catch((err) => {
                            toast.error(err?.response?.data?.message ?? err?.response?.data?.payload?.message ?? "Došlo je do greške!");
                        });
                }}
            />
        </>
    );
};

export default ChangesOnContracts;
