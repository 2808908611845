import { toast } from "react-toastify";
import useAPI from "../../api/api";
import ListPage from "../../components/shared/ListPage/ListPage";
import tblFields from "./tblFields.json";

const RolesListPage = () => {
    const api = useAPI();
    const screen_code = "ROLES";
    const customActions = {
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
            deleteClickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    api.delete(`admin/roles/list/${rowData.id}`, screen_code)
                        .then(() => toast.success("Zapis je uspešno obrisan"))
                        .catch(() => toast.warning("Došlo je do greške prilikom brisanja"));
                    return {
                        show: false,
                        id: rowData.id,
                        mutate: 1,
                    };
                },
            },
        },
    };

    return <ListPage screen_code={screen_code} apiUrl="admin/roles/list/list" title="Uloge" columnFields={tblFields} customActions={customActions} />;
};

export default RolesListPage;
