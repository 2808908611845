import { useEffect, useState } from "react";
import ListPage from "../../../components/shared/ListPage/ListPage";
import fields from "./fields.json";
import Button from "../../../components/shared/Button/Button";
import IconList from "../../../helpers/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import useAPI from "../../../api/api";
const Contacts = ({ client_id, screen_code, contractId, reloadTabs }) => {
    const init = {
        client_id: client_id,
        contracts_id: contractId,
    };
    const [data, setData] = useState(init);
    const [doesRefetch, setDoesRefetch] = useState(false);
    const [columnFields, setColumnFields] = useState(fields);
    const [formFields, setFormFields] = useState(fields);
    const api = useAPI();
    const [openModal, setOpenModal] = useState({
        open: false,
        id: null,
        type: "",
    });

    const customActions = {
        edit: {
            display: true,
            position: 1,
            clickHandler: {
                type: "modal_form",
                fnc: (rowData) => {
                    setOpenModal({
                        open: true,
                        id: rowData?.id,
                        type: "edit",
                    });
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
            title: "Izmeni",
        },
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    setOpenModal({
                        open: true,
                        id: rowData?.id,
                        type: "delete",
                    });
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
        },
    };
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const showConfirm = params.get("confirm");

    const handleScreenCode = (openModal) => {
        let code = "";
        switch (true) {
            case openModal?.id && openModal?.type === "edit":
                code = "COMMENTS_UPDATE";
                break;
            case openModal?.id && openModal?.type === "delete":
                code = "COMMENTS_DELETE";
                break;
            case !openModal?.id && !openModal?.type:
                code = "COMMENTS_LIST";
                break;
            case !openModal?.id && openModal?.type === "edit":
                code = "COMMENTS_NEW";
                break;
            default:
                code = "COMMENTS_LIST";
                break;
        }
        return code;
    };


    return (
        <>
            {showConfirm === "true" && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                        margin: "0 0 2rem 2rem",
                    }}
                >
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=advisors&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowBack}
                        text="Nazad"
                        label={`Prethodno`}
                    />
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=accounts&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowForward}
                        text="Napred"
                        label={`Sledeće`}
                    />
                </div>
            )}

                <ListPage
                    listPageId={`Comments${contractId}`}
                    screen_code={handleScreenCode(openModal)}
                    apiUrl={`admin/contracts/comments/list/list/${contractId}`}
                    editUrl={`admin/contracts/comments/basic-data`}
                    title="Komentari"
                    deleteUrl={`admin/contracts/comments/list`}
                    client_id={client_id}
                    reloadTabs={{
                        enabled: true,
                        fnc: reloadTabs,
                    }}
                    doesRefetch={doesRefetch}
                    setDoesRefetch={setDoesRefetch}
                    showNewButton={true}
                    initialData={data}
                    useColumnFields={true}
                    formFields={formFields}
                    editUrlQueryString={`?contracts_id=${contractId}`}
                    columnFields={columnFields}
                    setModalData={setOpenModal}
                    actionNewButton="modal"
                    customActions={customActions}
                />

        </>
    );
};

export default Contacts;
