import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { availableScreens } from "../routes/routes";
import sideNavLogoDark from "./../assets/images/croonus-sidebar-logo-dark.svg";
import sideNavLogoLight from "./../assets/images/croonus-sidebar-logo-light.svg";
import sideNavIcon from "./../assets/images/croonus-sidebar-icon.svg";
import AuthContext from "../store/auth-contex";
import Unicon from "./shared/Unicon/Unicon";

const SideNavigation = ({ activeTheme, userName }) => {
    const { userScreens } = useContext(AuthContext);

    // Populate the menu
    let menu = [];
    for (const allowedScreen of userScreens ?? []) {
        // Check for local screen definition
        const screen = availableScreens[allowedScreen.screen_code];

        if (screen) {
            // Init
            menu[screen.group.order] = menu[screen.group.order] ?? {
                name: screen.group.name,
                items: [],
            };
            // Add item
            menu[screen.group.order].items.push(screen);
        }
    }

    return (
        <nav id="sidebar">
            <NavLink to="/portal-customers" className="logo">
                <img
                    className={"img-fluid desktop-logo" + (activeTheme ? " dark-theme-logo" : " light-theme-logo")}
                    src={activeTheme ? sideNavLogoDark : sideNavLogoLight}
                    alt={activeTheme ? sideNavLogoDark : sideNavLogoLight}
                />
                <img className={"img-fluid mobile-logo" + (activeTheme ? " dark-theme-icon" : " light-theme-icon")} src={sideNavIcon} alt={sideNavIcon} />
            </NavLink>
            <div className="sidebar-welcome">
                <h5>Dobrodošli</h5>
                <p>{userName}</p>
            </div>
            <ul className="list-unstyled components mb-5 scroll-view">
                {menu.map((menuGroup) => (
                    <React.Fragment key={menuGroup.name}>
                        <li className="sidebar-categories">
                            <p>{menuGroup.name}</p>
                        </li>

                        {menuGroup.items.map((item) => {
                            const isHidden = item?.isHidden;
                            if (isHidden) {
                                return null;
                            } else {
                                return (
                                    <li key={item.name}>
                                        <NavLink to={item.path} activeClassName="active">
                                            <Unicon icon={item.icon} />
                                            <span>{item.name}</span>
                                        </NavLink>
                                    </li>
                                );
                            }
                        })}
                    </React.Fragment>
                ))}
            </ul>
        </nav>
    );
};

export default SideNavigation;
