import Form from "../../../../components/shared/Form/Form";
import useAPI from "../../../../api/api";
import { useEffect, useState } from "react";
import tblFields from "./tblFields.json";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

const Limits = ({ screen_code, client_id, contract_id, acc_id }) => {
    const [params] = useSearchParams();
    const confirm = params.get("confirm");
    const api = useAPI();
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
    const navigate = useNavigate();

    const init = {
        id: null,
        contracts_id: contract_id,
        user_id: acc_id,
    };
    const [data, setData] = useState(init);
    const [basicDataTemp, setBasicDataTemp] = useState(tblFields);

    const updateNewFieldsInDetails = (data, isNew) => {
        data.map((item, i) => {
            if (isNew) {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = true;
                }
            } else {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = false;
                }
            }
        });
        setBasicDataTemp([...data]);
    };

    const handleSubmit = (data) => {
        setIsLoadingOnSubmit(true);
        let oldId = data.id;
        api.post(`admin/contracts/accounts/limits`, data, data?.id ? "ACCOUNTS_LIMITS_UPDATE" : "ACCOUNTS_LIMITS_NEW")
            .then((response) => {
                toast.success("Uspešno");
                setData(response?.payload);
                if (confirm === "true") {
                    navigate(`/portal-customers/contracts/${client_id}/${contract_id}?tab=accounts&confirm=true`, { replace: true });
                } else {
                    navigate(`/portal-customers/contracts/${client_id}/${contract_id}?tab=accounts`, { replace: true });
                }

                setIsLoadingOnSubmit(false);
            })
            .catch((error) => {
                console.warn(error);
                toast.warning(error?.response?.data?.message ?? "Greška");
                setIsLoadingOnSubmit(false);
            });
    };

    const handleData = () => {
        api.get(`admin/contracts/accounts/limits/new?user_id=${acc_id}&contracts_id=${contract_id}`, "ACCOUNTS_LIMITS_DETAILS")
            .then((response) => {
                let temp_data = response?.payload;
                if (!temp_data?.user_id) {
                    temp_data.user_id = acc_id;
                }
                if (!temp_data?.contracts_id) {
                    temp_data.contracts_id = contract_id;
                }

                setData(temp_data);
                updateNewFieldsInDetails(tblFields, temp_data);
            })
            .catch((error) => console.warn(error));
    };

    useEffect(() => {
        handleData();
    }, []);

    const validateData = (data, field) => {
        let ret = data;
        switch (field) {
            case "new":
                updateNewFieldsInDetails(tblFields, ret.new);
                return ret;
            default:
                return ret;
        }
    };

    const {
        data: contract_changes,
        isLoading,
        isSuccess,
    } = useQuery(
        ["accountLimitsChanges", contract_id, acc_id, isLoadingOnSubmit],
        async () => {
            return await api.get(`admin/contracts/accounts/limits/edited-fields/${contract_id}?account_id=${acc_id}`, "CONTRACTS_DETAILS").then((res) => res?.payload);
        },
        {},
    );

    const formatFields = (data) => {
        const objectKeys = Object.keys(data);

        let temp_data = basicDataTemp?.map((item) => {
            if (objectKeys?.includes(item?.prop_name)) {
                return {
                    ...item,
                    description: `Za ovo polje je zatražena izmena. Zatražena nova vrednost: ${data[item?.prop_name]}`,
                };
            } else {
                return item;
            }
        });
        setBasicDataTemp(temp_data);
    };

    useEffect(() => {
        if (!isLoading && isSuccess) {
            const timeout = setTimeout(() => {
                formatFields(contract_changes);
            }, 200);
            return () => clearTimeout(timeout);
        }
    }, [isLoading]);

    return <Form screen_code={screen_code} formFields={basicDataTemp} initialData={data} client_id={client_id} onSubmit={handleSubmit} validateData={validateData} loading={isLoadingOnSubmit} />;
};

export default Limits;
