import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DetailsPage from "../../../components/shared/Details/DetailsPage";
import useAPI from "../../../api/api";
import IconList from "../../../helpers/icons";
import { setUrlQueryStringParam, getUrlQueryStringParam } from "../../../helpers/functions";
import tblFields from "./tblFields.json";
import Form from "../../../components/shared/Form/Form";
import Limits from "../Limits/Limits";
import ScoringPackages from "../ScoringPackages/ScoringPackages";
import Contacts from "../Contacts/Contacts";
import Comments from "../Comments/Comments";
import Advisors from "../Advisors/Advisors";
import { useQuery } from "react-query";
import Accounts from "../../Accounts/Accounts";
import Button from "../../../components/shared/Button/Button";

const ContractDetails = () => {
    const { contractId, customerId: client_id } = useParams();

    const navigate = useNavigate();
    let screen_code = "USERS";
    const activeTab = getUrlQueryStringParam("tab") ?? "basic";
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
    const api = useAPI();

    const init = {
        id: null,
        client_id: client_id,
    };
    const [data, setData] = useState(init);
    const [basicDataTemp, setBasicDataTemp] = useState(tblFields);

    const updateNewFieldsInDetails = (data, isNew) => {
        data.map((item, i) => {
            if (isNew) {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = true;
                }
            } else {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = false;
                }
            }
        });
        setBasicDataTemp([...data]);
    };

    const { data: availableTabs, refetch: reloadTabs } = useQuery({
        queryKey: ["contractTabs", contractId],
        queryFn: async () => {
            return await api.get(`admin/contracts/basic-data/progress-step/${contractId}`, "CONTRACTS_DETAILS").then((res) => res?.payload);
        },
        refetchOnWindowFocus: false,
    });

    const [params] = useSearchParams();
    const showConfirm = params.get("confirm");

    const handleSubmit = (data) => {
        setIsLoadingOnSubmit(true);
        let oldId = data.id;
        api.post(`admin/contracts/basic-data`, data, data?.id ? "CONTRACTS_UPDATE" : "CONTRACTS_NEW")
            .then((response) => {
                setData(response?.payload);
                if (showConfirm === "true") {
                    navigate(`/portal-customers/contracts/${client_id}/${response?.payload?.id}?tab=scoring_packages&confirm=true`, { replace: true });
                } else {
                    navigate(`/portal-customers/contracts/${client_id}/${response?.payload?.id}?tab=scoring_packages`, { replace: true });
                }
                switch (response?.code) {
                    case 200:
                        toast.success("Uspešno");
                        reloadTabs();
                        break;
                    default:
                        toast.warning(response?.message ?? "Greška");
                        break;
                }
                setIsLoadingOnSubmit(false);
            })
            .catch((res, error) => {
                console.warn(res, error);
                setIsLoadingOnSubmit(false);
            });
    };

    const handleData = () => {
        api.get(`admin/contracts/basic-data/${contractId}?client_id=${client_id}`, "CONTRACTS_DETAILS")
            .then((response) => {
                let temp_data = response?.payload;
                if (!temp_data?.client_id) {
                    temp_data.client_id = client_id;
                }

                setData(temp_data);
                updateNewFieldsInDetails(tblFields, temp_data);
            })
            .catch((error) => {
                toast.warn(error?.response?.data?.message ?? "Greška");
                navigate(-1);
            });
    };

    useEffect(() => {
        handleData();
    }, []);

    const validateData = (data, field) => {
        let ret = data;
        let from_date = new Date(data?.from_date);
        switch (field) {
            case "number_months":
                from_date.setMonth(from_date.getMonth() + parseInt(data?.number_months));
                from_date.setDate(from_date.getDate() + parseInt(data?.number_days));
                ret.expire_at = new Date(from_date);
                return ret;

            case "from_date":
                from_date.setMonth(from_date.getMonth() + parseInt(data?.number_months));
                from_date.setDate(from_date.getDate() + parseInt(data?.number_days));
                ret.expire_at = new Date(from_date);
                return ret;

            case "number_days":
                from_date.setMonth(from_date.getMonth() + parseInt(data?.number_months));
                ret.expire_at = new Date(from_date.setDate(from_date.getDate() + parseInt(data?.number_days)));
                return ret;

            default:
                return ret;
        }
    };

    const { data: clientInfo } = useQuery(
        ["contracts", client_id],
        async () => {
            return await api.get(`admin/contracts/main/data/${client_id}`, "PORTAL_CONTRACTS_MAIN_DETAILS").then((res) => res?.payload);
        },
        { refetchOnWindowFocus: false },
    );

    const formatFormFields = (data) => {
        const objectKeys = Object.keys(data);

        let temp_data = basicDataTemp?.map((item) => {
            if (objectKeys?.includes(item?.prop_name)) {
                return {
                    ...item,
                    description: `Za ovo polje je zatražena izmena. Zatražena nova vrednost: ${data[item?.prop_name]}`,
                };
            } else {
                return item;
            }
        });
        setBasicDataTemp(temp_data);
    };

    const {
        data: contract_changes,
        isLoading,
        isSuccess,
    } = useQuery(
        ["contractsBasicDataChanges", contractId, isLoadingOnSubmit],
        async () => {
            return await api.get(`admin/contracts/basic-data/edited-fields/${contractId}`, "CONTRACTS_DETAILS").then((res) => {
                formatFormFields(res?.payload);
                return res?.payload;
            });
        },
        {},
    );

    const panels = [
        {
            id: "basic",
            name: "Osnovno",
            icon: IconList.inventory,
            enabled: availableTabs?.includes("basic") && showConfirm !== "true",
            component: (
                <>
                    {showConfirm === "true" && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: "10px",
                                margin: "0 0 2rem 0",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=scoring_packages&confirm=true`, { replace: true });
                                }}
                                icon={IconList.arrowForward}
                                text="Napred"
                                label={`Sledeće`}
                            />
                        </div>
                    )}
                    <Form
                        screen_code={"CONTRACTS_UPDATE"}
                        formFields={basicDataTemp}
                        initialData={data}
                        client_id={client_id}
                        onSubmit={handleSubmit}
                        validateData={validateData}
                        isLoading={isLoadingOnSubmit}
                    />
                </>
            ),
        },
        {
            id: "scoring_packages",
            name: "Scoring Paketi",
            icon: IconList.inventory,
            enabled: availableTabs?.includes("scoring_packages") && showConfirm !== "true",
            component: <ScoringPackages client_id={client_id} screen_code={contractId ? "SCORING_PACKAGES_UPDATE" : "SCORING_PACKAGES_NEW"} contractId={contractId} reloadTabs={reloadTabs} />,
        },
        {
            id: "limits",
            name: "Limiti",
            icon: IconList.lockPerson,
            enabled: availableTabs?.includes("limits") && showConfirm !== "true",
            component: <Limits client_id={client_id} screen_code={contractId ? "LIMITS_UPDATE" : "LIMITS_NEW"} contractId={contractId} reloadTabs={reloadTabs} />,
        },
        {
            id: "contacts",
            name: "Kontakti",
            icon: IconList.contactPage,
            enabled: availableTabs?.includes("contacts") && showConfirm !== "true",
            component: <Contacts client_id={client_id} screen_code={screen_code} contractId={contractId} reloadTabs={reloadTabs} />,
        },
        {
            id: "advisors",
            name: "Savetnici",
            icon: IconList.person,
            enabled: availableTabs?.includes("advisors") && showConfirm !== "true",
            component: <Advisors client_id={client_id} screen_code={screen_code} contractId={contractId} reloadTabs={reloadTabs} />,
        },
        {
            id: "comments",
            name: "Komentari",
            icon: IconList.comment,
            enabled: availableTabs?.includes("comments") && showConfirm !== "true",
            component: <Comments client_id={client_id} screen_code={screen_code} contractId={contractId} reloadTabs={reloadTabs} />,
        },
        {
            id: "accounts",
            name: "Nalozi",
            icon: IconList.group,
            enabled: availableTabs?.includes("done") && showConfirm !== "true",
            component: <Accounts />,
        },
    ];

    // Handle after click on tab panel
    const panelHandleSelect = (field) => {
        let queryString = setUrlQueryStringParam("tab", field.id);
        // const id = data.id == null ? "new" : client_id;
        navigate(`/portal-customers/contracts/${client_id}/${contractId ?? "new"}?${queryString}`, { replace: true });
    };

    return (
        <DetailsPage
            title={contractId !== "new" ? `Ugovor za klijenta ${clientInfo?.name}` : `Novi ugovor za klijenta ${clientInfo?.name}`}
            fields={panels}
            ready={[contractId === "new" || data?.id]}
            selectedPanel={activeTab}
            panelHandleSelect={panelHandleSelect}
        />
    );
};

export default ContractDetails;
