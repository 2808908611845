import useAPI from "../../../api/api";
import { useNavigate, useParams } from "react-router-dom";

import Form from "../../../components/shared/Form/Form";
import PageWrapper from "../../../components/shared/Layout/PageWrapper/PageWrapper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconList from "../../../helpers/icons";
import Button from "../../../components/shared/Button/Button";
import DetailsPage from "../../../components/shared/Details/DetailsPage";
import { Basic } from "./panels/basic/basic";
import { getUrlQueryStringParam, setUrlQueryStringParam } from "../../../helpers/functions";
import { ActiveContracts } from "./panels/contracts/active-contracts/active-contracts";
import { DemoAccounts } from "./panels/demo-accounts/demo-accounts";
import { InactiveContracts } from "./panels/contracts/inactive-contracts/inactive-contracts";

export const RegistrationDetails = () => {
    const { type, rid, cid } = useParams();

    const activeTab = getUrlQueryStringParam("tab") ?? "basic";
    const navigate = useNavigate();

    const panels = [
        {
            id: "basic",
            name: "Osnovno",
            icon: IconList.inventory,
            enabled: true,
            component: <Basic rid={rid} cid={cid} type={type} />,
        },
        {
            id: "active-contracts",
            name: "Aktivni ugovori",
            icon: IconList.inventory,
            enabled: true,
            component: <ActiveContracts rid={rid} cid={cid} type={type} />,
        },
        {
            id: "inactive-contracts",
            name: "Neaktivni ugovori",
            icon: IconList.inventory,
            enabled: true,
            component: <InactiveContracts rid={rid} cid={cid} type={type} />,
        },
        {
            id: "demo-accounts",
            name: "Demo nalozi",
            icon: IconList.inventory,
            enabled: true,
            component: <DemoAccounts rid={rid} cid={cid} type={type} />,
        },
    ];

    // Handle after click on tab panel
    const panelHandleSelect = (field) => {
        let queryString = setUrlQueryStringParam("tab", field.id);
        navigate(`/registrations/${type}/${rid}/${cid}?${queryString}`, { replace: true });
    };

    return <DetailsPage fields={panels} title={`Detalji registracije`} screen_code={`CONTRACTS_UPDATE`} selectedPanel={activeTab} panelHandleSelect={panelHandleSelect} />;
};
