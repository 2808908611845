import React, { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactImageGallery from "react-image-gallery";
import Typography from "@mui/material/Typography";
import images from "../../../assets/images/download.png";
import { InputWrapper } from "../Form/FormInputs/FormInputs";

const Slider = ({
    label = "",
    required = false,
    error = "",
    emptyMessage = "Trenutno nema slike/videa za prikaz.",
    dataFromServer = [],
    margin,
    disabled,
    sliderOptions = {
        lazyLoad: true,
        showThumbnails: true,
        showFullscreenButton: true,
        showPlayButton: true,
    },
    imageConfig = {
        className: "sliderImage",
    },
    sliderConfig = {
        className: "sliderVideo",
    },
}) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (JSON.stringify(dataFromServer) !== JSON.stringify(items)) {
            setItems(dataFromServer);
        }
    }, [dataFromServer, items]);

    const renderItem = (item) => {
        if (item.type === "video") {
            return <video src={item.url} autoPlay controls style={{ width: "100%" }} {...sliderConfig} />;
        } else if (item.type === "image") {
            return <img src={item.url} alt={item.alt} style={{ width: "100%" }} {...imageConfig} />;
        }

        return null;
    };

    return (
        <>
            <InputWrapper label={label} required={required} disabled={disabled} margin={margin} error={error}>
                {items.length > 0 ? (
                    <ReactImageGallery
                        items={items.map((item) => ({
                            original: item.url,
                            thumbnail: item.thumbnail === "default-missing-image" ? images : item.thumbnail,
                            renderItem: () => renderItem(item),
                            ...imageConfig,
                            ...sliderConfig,
                        }))}
                        {...sliderOptions}
                    />
                ) : (
                    <Typography variant="subtitle1">{emptyMessage}</Typography>
                )}
            </InputWrapper>
        </>
    );
};

export default Slider;
