import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DetailsPage from "../../../components/shared/Details/DetailsPage";
import useAPI from "../../../api/api";
import IconList from "../../../helpers/icons";
import { setUrlQueryStringParam, getUrlQueryStringParam } from "../../../helpers/functions";
import tblFields from "./tblFields.json";
import Form from "../../../components/shared/Form/Form";
import Limits from "./Limits/Limits";
import { useQuery } from "react-query";
import ScoringPackages from "./ScoringPackages/ScoringPackages";

const AccountDetails = () => {
    const [params] = useSearchParams();
    const confirm = params.get("confirm");
    const { accId, contractId, clientId } = useParams();
    const navigate = useNavigate();
    let screen_code = "USERS";
    const activeTab = getUrlQueryStringParam("tab") ?? "basic";
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
    const api = useAPI();

    const { data: availableTabs, refetch: reloadTabs } = useQuery({
        queryKey: ["accountsTabs", accId],
        queryFn: async () => {
            return await api.get(`admin/contracts/accounts/basic-data/progress-step/${accId}`, "ACCOUNTS_DETAILS").then((res) => res?.payload);
        },
        refetchOnWindowFocus: false,
    });

    const init = {
        id: null,
        client_id: clientId,
        contracts_id: contractId,
        user_id: accId,
    };

    const [data, setData] = useState(init);

    const [basicDataTemp, setBasicDataTemp] = useState(tblFields);

    const updateNewFieldsInDetails = (data, isNew) => {
        data.map((item, i) => {
            if (isNew) {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = true;
                }
            } else {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = false;
                }
            }
        });
        setBasicDataTemp([...data]);
    };

    const handleSubmit = (data) => {
        setIsLoadingOnSubmit(true);
        let oldId = data.id;
        api.post(`admin/contracts/accounts/basic-data`, data, data?.id ? "ACCOUNTS_UPDATE" : "ACCOUNTS_NEW")
            .then((response) => {
                setData(response?.payload);
                toast.success("Uspešno");
                reloadTabs();

                if (confirm === "true") {
                    navigate(`/accounts/${clientId}/${contractId}/${response?.payload?.id}?tab=scoring_packages&confirm=true`, { replace: true });
                } else {
                    navigate(`/accounts/${clientId}/${contractId}/${response?.payload?.id}?tab=scoring_packages`, { replace: true });
                }

                setIsLoadingOnSubmit(false);
            })
            .catch((error) => {
                console.warn(error);
                toast.warn(error?.response?.data?.message ?? "Greška");
                setIsLoadingOnSubmit(false);
            });
    };

    const handleData = () => {
        api.get(`admin/contracts/accounts/basic-data/${accId}?contracts_id=${contractId}`, "ACCOUNTS_DETAILS")
            .then((response) => {
                let temp_data = response?.payload;
                if (!temp_data?.client_id) {
                    temp_data.client_id = clientId;
                }
                if (!temp_data?.contracts_id) {
                    temp_data.contracts_id = contractId;
                }

                setData(temp_data);
                updateNewFieldsInDetails(tblFields, temp_data);
            })
            .catch((error) => console.warn(error));
    };

    useEffect(() => {
        handleData();
    }, []);

    const validateData = (data, field) => {
        let ret = data;
        switch (field) {
            case "new":
                updateNewFieldsInDetails(tblFields, ret.new);
                return ret;
            default:
                return ret;
        }
    };

    const formatFormFields = (fields) => {
        let arr = [];
        fields.map((item, i) => {
            if (item.prop_name === "status") {
                item.usePropName = false;
                item.fillFromApi = item.fillFromApi + "/status?contracts_id=" + contractId;
            }
            arr.push(item);
        });

        setBasicDataTemp([...arr]);
    };

    useEffect(() => {
        formatFormFields(basicDataTemp);
    }, []);

    const formatFields = (data) => {
        const objectKeys = Object.keys(data);

        let temp_data = basicDataTemp?.map((item) => {
            if (objectKeys?.includes(item?.prop_name)) {
                return {
                    ...item,
                    description: `Za ovo polje je zatražena izmena. Zatražena nova vrednost: ${data[item?.prop_name]}`,
                };
            } else {
                return item;
            }
        });
        setBasicDataTemp(temp_data);
    };

    const {
        data: contract_changes,
        isLoading,
        isSuccess,
    } = useQuery(
        ["accountBasicDataChanges", contractId, isLoadingOnSubmit],
        async () => {
            return await api.get(`admin/contracts/accounts/basic-data/edited-fields/${contractId}?account_id=${accId}`, "CONTRACTS_DETAILS").then((res) => {
                formatFields(res?.payload);
                return res?.payload;
            });
        },
        {},
    );
    const fields = [
        {
            id: "basic",
            name: "Osnovno",
            icon: IconList.inventory,
            enabled: availableTabs?.includes("basic"),
            component: (
                <Form
                    screen_code={"ACCOUNTS_DETAILS"}
                    formFields={basicDataTemp}
                    initialData={data}
                    client_id={accId}
                    onSubmit={handleSubmit}
                    validateData={validateData}
                    loading={isLoadingOnSubmit}
                />
            ),
        },
        {
            id: "scoring_packages",
            name: "Scoring paketi",
            icon: IconList.inventory,
            enabled: availableTabs?.includes("scoring_packages"),
            component: <ScoringPackages client_id={clientId} screen_code={screen_code} acc_id={accId} contract_id={contractId} reloadTabs={reloadTabs} />,
        },
        {
            id: "limits",
            name: "Limiti",
            icon: IconList.lockPerson,
            enabled: availableTabs?.includes("limits"),
            component: <Limits client_id={clientId} screen_code={screen_code} acc_id={accId} contract_id={contractId} reloadTabs={reloadTabs} />,
        },
    ];

    // Handle after click on tab panel
    const panelHandleSelect = (field) => {
        let queryString = setUrlQueryStringParam("tab", field.id);
        // const id = data.id == null ? "new" : client_id;
        navigate(`/accounts/${clientId}/${contractId}/${accId ?? "new"}?${queryString}`, { replace: true });
    };

    return <DetailsPage title={data?.id == null ? "Nalozi" : `Nalozi`} fields={fields} ready={[accId === "new" || data?.id]} selectedPanel={activeTab} panelHandleSelect={panelHandleSelect} />;
};

export default AccountDetails;
