import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Form from "../../components/shared/Form/Form";
import fieldsPersonal from "./RegistrationDetails/panels/basic/fieldspersonal.json";
import fields from "./fields.json";
import { useEffect, useState } from "react";
import PageWrapper from "../../components/Layout/PageWrapper/PageWrapper";
import { useMutation, useQuery } from "react-query";
import useAPI from "../../api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "../../components/shared/Button/Button";

export const NewRegistration = ({ cid, pid, type }) => {
    const [data, setData] = useState();
    const [fieldsTemp, setFieldsTemp] = useState(fields);
    const api = useAPI();
    const { data: initialData, isLoading } = useQuery(
        ["registrationDetails"],
        async () => {
            return await api.get(`admin/registration/main/new`, "REGISTRATIONS_MAIN_DETAILS").then((res) => {
                setData(res?.payload);
            });
        },
        { refetchOnWindowFocus: false }
    );

    const onChange = (data) => {
        setData(data);
    };

    const { refetch } = useQuery({
        queryKey: ["companyDataInfo", data?.firma_id],
        queryFn: async () => {
            if (data?.firma_id) {
                return await api
                    .get(`admin/registration/main/company/${data?.firma_id}`, "REGISTRATIONS_MAIN_DETAILS")
                    .then((res) => {
                        setData({
                            ...data,
                            name: res?.payload?.naziv,
                            id: null,
                            country_id: res?.payload?.country_id,
                            firma_id: res?.payload?.firma_id,
                            pib: res?.payload?.pib,
                            maticni_broj: res?.payload?.maticni_broj,
                            city: res?.payload?.mesto,
                            address: res?.payload?.adresa,
                            postal_code: res?.payload?.postal_code,
                            slug: res?.payload?.slug,
                        });
                    })
                    ?.catch((err) => err);
            }
        },
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        refetch();
    }, [data?.firma_id]);

    const navigate = useNavigate();
    const { mutate: accept, isLoading: isAccepting } = useMutation(["acceptAccount"], async () => {
        return await api
            .post(
                `admin/registration/main`,
                {
                    ...data,
                    accept_policy: 1,
                    accept_terms: 1,
                },
                "REGISTRATIONS_MAIN_DETAILS"
            )
            .then((res) => {
                toast.success(res?.payload?.message ?? `Uspešno ste registrovali korisnika!`);
                navigate(`/portal-customers/contracts/${res?.payload?.contract?.client_id}/${res?.payload?.contract?.id}`);
            })
            ?.catch((err) => {
                toast.error(err?.response?.data?.message ?? "Greška");
            });
    });

    return (
        <PageWrapper title={`Nova registracija`}>
            <Box
                sx={{
                    display: "flex",
                    gap: "2rem",
                    flexWrap: "wrap",
                }}
            >
                <Box sx={{ flex: "1" }}>
                    <Typography variant={`h6`} sx={{ paddingBottom: "1rem" }}>
                        Informacije o kompaniji
                    </Typography>
                    <Form
                        asyncValidateData={(data) => {
                            setData(data);
                            return data;
                        }}
                        screen_code={`REGISTRATIONS_MAIN_DETAILS`}
                        formFields={fieldsTemp}
                        initialData={data}
                        submitButton={false}
                        label={`Podaci o kompaniji`}
                        onChange={onChange}
                    />
                </Box>

                <Box sx={{ flex: "1" }}>
                    <Typography variant={`h6`} sx={{ paddingBottom: "1rem" }}>
                        Informacije o predstavniku
                    </Typography>

                    <Form formFields={fieldsPersonal} initialData={data} submitButton={false} onChange={onChange} />
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button label={`Sačuvaj`} onClick={accept} disabled={isAccepting} variant={`contained`} type={`submit`} sx={{ marginLeft: "auto" }} />
            </Box>
        </PageWrapper>
    );
};
