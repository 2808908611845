import tblFields from "./tblFields.json";
import { useMutation, useQuery } from "react-query";
import useAPI from "../../../../api/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ListPage from "../../../../components/shared/ListPage/ListPage";
import DeleteModal from "../../../../components/shared/Dialogs/DeleteDialog";
import dataCheckbox from "./dataCheckbox.json";
import ModalForm from "../../../../components/shared/Modal/ModalForm";
const ToBeExpired = () => {
    const api = useAPI();
    const navigate = useNavigate();
    const [dataFields, setDataFields] = useState(dataCheckbox);

    const [openModal, setOpenModal] = useState({
        show: false,
        id: null,
        name: null,
        client_id: null,
        data: [],
        type: "",
    });

    const [doesRefetch, setDoesRefetch] = useState(false);
    let screen_code = "USERS";
    const [openModalUncheckedSet, setOpenModalUncheckedSet] = useState({ show: false, type: "" });

    const [formFields, setFormFields] = useState(tblFields);

    const actions = {
        delete: {
            type: "delete",
            display: false,
        },
        edit: {
            type: "edit",
            display: true,
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/portal-customers/contracts/${rowData?.client_id}/${rowData?.id}`;
                },
            },
        },
        clone: {
            type: "custom",
            display: true,
            position: 2,
            icon: "file_copy",
            title: "Dupliraj ugovor",
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    setOpenModal({ show: true, id: rowData.id, name: rowData.name, type: "clone", client_id: rowData.client_id });
                },
            },
        },
    };

    const confirmHandler = async (id, client_id) => {
        api.post(
            `admin/contracts/close-to-expire/clone`,
            {
                client_id: client_id,
                contracts_id: id,
            },
            "CONTRACTS_CLONE"
        )
            .then((response) => {
                setDoesRefetch(true);
                toast.success("Uspešno");
            })
            .catch((error) => {
                toast.warn(error?.response?.data?.payload?.message ?? error?.response?.data?.message ?? "Greška");
                console.warn(error);
            });
    };

    const buttons = [
        {
            label: "Nazad",
            icon: "arrow_back",
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    navigate(-1);
                },
            },
        },
    ];

    const handleCloneMessage = (id) => {
        return useQuery(
            ["cloneMessage", id],
            async () => {
                return await api.get(`/admin/contracts/close-to-expire/clone/${id}`, "CONTRACTS_CLONE").then((res) => res?.payload);
            },
            {
                enabled: Boolean(openModal?.id),
            }
        );
    };

    const { data: cloneMessage, refetch: refetch_message } = handleCloneMessage(openModal?.id);

    const handleCloneOptions = () => {
        return useQuery(["cloneOptions", openModal?.id], async () => {
            return await api.get(`/admin/contracts/close-to-expire/ddl/clone-options`, "CONTRACTS_CLONE").then((res) => res?.payload);
        });
    };

    // const { data: cloneOptions, refetch: refetch_clone_options } = handleCloneOptions();

    useEffect(() => {
        if (openModal?.id && openModal?.id !== undefined && openModal?.id !== null) {
            refetch_message();
            // refetch_clone_options();
        }
    }, [openModal?.id]);

    const mutate_options = useMutation(["check_options", openModal?.id], async ({ data }) => {
        return await api.post(`admin/contracts/close-to-expire/ddl/clone-disabled-options`, data, "CONTRACTS_CLONE").then((res) => {
            let temp = dataFields?.map((item) => {
                //
                if (res?.payload?.disabled?.includes(item.prop_name) || item.prop_name === "basic_data") {
                    return {
                        ...item,
                        disabled: true,
                    };
                } else {
                    return {
                        ...item,
                        disabled: false,
                    };
                }

                return item;
            });
            setDataFields(temp);
            return res?.payload;
        });
    });

    const validateData = (data, field) => {
        let ret = data;
        if (field === "renewal" || field === "start_from_today") {
            let renewal_val = Number(data["renewal"]);
            let start_from_today_val = Number(data["start_from_today"]);
            mutate_options.mutate({
                data: {
                    renewal: data["renewal"],
                    start_from_today: data["start_from_today"],
                },
            });
            if (renewal_val === 1 || start_from_today_val === 1) {
                ret["account_basic_data"] = 1;
                ret["account_scoring_packages"] = 1;
                ret["account_limits"] = 1;
                ret["scoring_packages"] = 1;
                ret["limits"] = 1;
                return ret;
            }
        }

        return ret;
    };

    useEffect(() => {
        mutate_options.mutate({
            data: {
                renewal: 1,
                start_from_today: 1,
            },
        });
    }, []);

    return (
        <>
            <ListPage
                showNewButton={false}
                apiUrl={`admin/contracts/close-to-expire/list`}
                customActions={actions}
                columnFields={formFields}
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
                formFields={formFields}
                validateData={validateData}
                useColumnFields={false}
                screen_code={`PORTAL_CONTRACTS_CLOSE_TO_EXPIRE_LIST`}
                additionalButtons={buttons}
                title={`Predistek ugovori`}
            />
            {openModal?.type === "clone" && (
                <ModalForm
                    anchor="right"
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    formFields={dataFields}
                    sx={{ padding: "2rem" }}
                    apiPathFormModal="/admin/contracts/close-to-expire/ddl/clone-options"
                    apiPathFormModalWithoutId={`/admin/contracts/close-to-expire/clone`}
                    withoutSetterFunction
                    initialData={{ contracts_id: openModal.id, client_id: openModal?.client_id }}
                    cancelButton
                    withoutId
                    useNavigation={{
                        enabled: true,
                        basePath: `/portal-customers/contracts/${openModal?.client_id}/`,
                    }}
                    validateData={validateData}
                    label="Dupliraj"
                    styleCheckbox={{ padding: "0 0.563rem 0 0.563rem" }}
                    customTitle={cloneMessage?.main_line}
                    screen_code={"CONTRACTS_CLONE"}
                    setDoesRefetch={setDoesRefetch}
                />
            )}
        </>
    );
};

export default ToBeExpired;
