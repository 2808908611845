import { useEffect, useState, useMemo } from "react";
import ListPage from "../../../components/shared/ListPage/ListPage";
import fields from "./fields.json";
import Button from "../../../components/shared/Button/Button";
import IconList from "../../../helpers/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import useAPI from "../../../api/api";

const Contacts = ({ client_id, screen_code, contractId, reloadTabs }) => {
    const init = {
        client_id: client_id,
        contracts_id: contractId,
    };
    const [data, setData] = useState(init);
    const [doesRefetch, setDoesRefetch] = useState(false);
    const [columnFields, setColumnFields] = useState(fields);
    const [formFields, setFormFields] = useState(fields);
    const [openModal, setOpenModal] = useState({
        open: false,
        id: null,
        type: "",
    });
    const api = useAPI();
    const [rewrite, setRewrite] = useState(true);
    const customActions = {
        edit: {
            display: true,
            position: 1,
            clickHandler: {
                type: "modal_form",
                fnc: (rowData) => {
                    setOpenModal({
                        open: true,
                        id: rowData?.id,
                        type: "edit",
                    });
                    setRewrite(!rewrite);
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
            title: "Izmeni",
        },
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    setOpenModal({
                        open: true,
                        id: rowData?.id,

                        type: "delete",
                    });
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
        },
    };

    const validateData = (data, field) => {
        let ret = data;
        switch (field) {
            default:
                return ret;
        }
    };
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const showConfirm = params.get("confirm");

    const handleScreenCode = (openModal) => {
        let code = "";
        switch (true) {
            case openModal?.id && openModal?.type === "edit":
                code = "CONTACTS_UPDATE";
                break;
            case openModal?.id && openModal?.type === "delete":
                code = "CONTACTS_DELETE";
                break;
            case !openModal?.id && !openModal?.type:
                code = "CONTACTS_LIST";
                break;
            case !openModal?.id && openModal?.type === "edit":
                code = "CONTACTS_NEW";
                break;
            default:
                code = "CONTACTS_LIST";
                break;
        }
        return code;
    };

    const formatFormFields = (data) => {
        const objectKeys = Object.keys(data);

        let temp_data = formFields?.map((item) => {
            if (objectKeys?.includes(item?.prop_name)) {
                return {
                    ...item,
                    description: `Za ovo polje je zatražena izmena. Zatražena nova vrednost: ${data[item?.prop_name]}`,
                };
            } else {
                return item;
            }
        });
        setFormFields(temp_data);
    };

    const {
        data: contract_changes,
        isLoading,
        isSuccess,
        refetch,
    } = useQuery(
        ["contractsContactsChanges", contractId, rewrite],
        async () => {
            return await api.get(`admin/contracts/contacts/basic-data/edited-fields/${contractId}?contact_id=${openModal?.id}`, "CONTRACTS_DETAILS").then((res) => {
                formatFormFields(res?.payload);
                return res?.payload;
            });
        },
        {
            enabled: openModal?.open,
            refetchOnWindowFocus: true,
        },
    );

    return (
        <>
            {showConfirm === "true" && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                        margin: "0 0 2rem 2rem",
                    }}
                >
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=limits&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowBack}
                        text="Nazad"
                        label={`Prethodno`}
                    />
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=advisors&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowForward}
                        text="Napred"
                        label={`Sledeće`}
                    />
                </div>
            )}

            <ListPage
                listPageId={`Contacts${contractId}`}
                screen_code={handleScreenCode(openModal)}
                apiUrl={`admin/contracts/contacts/list/list/${contractId}`}
                editUrl={`admin/contracts/contacts/basic-data`}
                title="Kontakti"
                reloadTabs={{
                    enabled: true,
                    fnc: reloadTabs,
                }}
                deleteUrl={`admin/contracts/contacts/list`}
                client_id={client_id}
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
                initialData={data}
                useColumnFields={false}
                showNewButton
                formFields={formFields}
                editUrlQueryString={`?contracts_id=${contractId}`}
                columnFields={columnFields}
                validateData={validateData}
                actionNewButton="modal"
                setModalData={setOpenModal}
                customActions={customActions}
            />
        </>
    );
};

export default Contacts;
