import Form from "../../../components/shared/Form/Form";
import useAPI from "../../../api/api";
import { useEffect, useState } from "react";
import tblFields from "./tblFields.json";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../components/shared/Button/Button";
import IconList from "../../../helpers/icons";
import { useQuery } from "react-query";

const Limits = ({ screen_code, client_id, contractId, reloadTabs }) => {
    const api = useAPI();
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
    const navigate = useNavigate();
    const init = {
        id: null,
        contracts_id: contractId,
    };
    const [data, setData] = useState(init);
    const [basicDataTemp, setBasicDataTemp] = useState(tblFields);

    const updateNewFieldsInDetails = (data, isNew) => {
        data.map((item, i) => {
            if (isNew) {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = true;
                }
            } else {
                if (item.prop_name === "new_from" || item.prop_name === "new_to") {
                    item.in_details = false;
                }
            }
        });
        setBasicDataTemp([...data]);
    };

    const [params] = useSearchParams();
    const showConfirm = params.get("confirm");
    const handleSubmit = (data) => {
        setIsLoadingOnSubmit(true);
        let oldId = data.id;
        api.post(`admin/contracts/limits`, data, screen_code)
            .then((response) => {
                toast.success("Uspešno");
                setData(response?.payload);
                if (showConfirm === "true") {
                    navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=contacts&confirm=true`, { replace: true });
                } else {
                    navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=contacts`, { replace: true });
                }
                reloadTabs();
                setIsLoadingOnSubmit(false);
            })
            .catch((error) => {
                console.warn(error);
                toast.warning(error?.response?.data?.message ?? "Greška");
                setIsLoadingOnSubmit(false);
            });
    };

    const handleData = () => {
        api.get(`admin/contracts/limits/new?contracts_id=${contractId}`, screen_code)
            .then((response) => {
                let temp_data = response?.payload;
                if (!temp_data?.contracts_id) {
                    temp_data.contracts_id = contractId;
                }

                setData(temp_data);
                updateNewFieldsInDetails(tblFields, temp_data);
            })
            .catch((error) => {
                toast.warn(error?.response?.data?.message ?? "Greška");
            });
    };

    useEffect(() => {
        handleData();
    }, []);

    const validateData = (data, field) => {
        let ret = data;
        switch (field) {
            case "new":
                updateNewFieldsInDetails(tblFields, ret.new);
                return ret;
            default:
                return ret;
        }
    };

    const {
        data: contract_changes,
        isLoading,
        isSuccess,
    } = useQuery(
        ["contractsLimitsChanges", contractId, isLoadingOnSubmit],
        async () => {
            return await api.get(`admin/contracts/limits/edited-fields/${contractId}`, "CONTRACTS_DETAILS").then((res) => res?.payload);
        },
        {},
    );

    const formatFields = (data) => {
        const objectKeys = Object.keys(data);

        let temp_data = basicDataTemp?.map((item) => {
            if (objectKeys?.includes(item?.prop_name)) {
                return {
                    ...item,
                    description: `Za ovo polje je zatražena izmena. Zatražena nova vrednost: ${data[item?.prop_name]}`,
                };
            } else {
                return item;
            }
        });
        setBasicDataTemp(temp_data);
    };

    useEffect(() => {
        if (!isLoading && isSuccess) {
            const timeout = setTimeout(() => {
                formatFields(contract_changes);
            }, 200);
            return () => clearTimeout(timeout);
        }
    }, [isLoading]);

    return (
        <>
            {showConfirm === "true" && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                        margin: "0 0 2rem 0",
                    }}
                >
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=scoring_packages&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowBack}
                        text="Nazad"
                        label={`Prethodno`}
                    />
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=contacts&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowForward}
                        text="Napred"
                        label={`Sledeće`}
                    />
                </div>
            )}
            <Form screen_code={screen_code} formFields={basicDataTemp} initialData={data} client_id={contractId} onSubmit={handleSubmit} validateData={validateData} isLoading={isLoadingOnSubmit} />
        </>
    );
};

export default Limits;
