import React, { useEffect, useState } from "react";

import Form from "../Form/Form";
import useAPI from "../../../api/api";
import { toast } from "react-toastify";
import FormWrapper from "../Layout/FormWrapper/FormWrapper";
import ListPageModalWrapper from "./ListPageModalWrapper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

/**
 * Modal.
 *
 * @param anchor Side from which the drawer will appear ('bottom'|'left'|'right'|'top').
 * @param selectedRowData
 * @param selectedRowData
 * @param {bool} openModal Depending on what we set true or false, the modal opens or closes.
 * @param {function} setOpenModal
 * @param sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @param queryString
 * @param {'permanent'|'persistent'|'temporary'} variant The variant to use.
 * @param {string} apiPathFormModal Api path.
 * @param {FieldSpec[]} formFields
 * @param initialData
 * @param label
 * @param {string} customTitle
 * @param shortText
 * @param cancelButton
 * @param withoutSetterFunction
 * @param children
 * @param {string} screen_code
 * @param styleCheckbox
 *
 * @param children
 * @param {string} screen_code
 * @param validateData
 * @param asyncValidateData
 * @param reloadTabs
 * @param useNavigation
 * @param withoutId
 * @param resetData
 * @param apiPathFormModalWithoutId
 * @param setDoesRefetch
 * @return {JSX.Element}
 * @constructor
 */

const ModalForm = ({
    anchor,
    setPropName,
    setOpenModal,
    openModal,
    savePrepareDataHandler = null,
    sx,
    variant,
    apiPathFormModal,
    saveMethod = "post",
    formFields,
    initialData = {},
    label,
    customTitle,
    shortText,
    cancelButton,
    submitButton,
    closeButtonModalForm,
    clearButton = false,
    withoutSetterFunction = false,
    styleCheckbox,
    children,
    queryString = [],
    validateData = (data) => data,
    modalObject = null,
    customTitleDataNameForEdit = "Izmeni",
    useModalGalleryInjection = false,
    allowedFileTypes,
    onFilePicked,
    selectedFile,
    onDismissModal = () => {},
    handleRemoveFile,
    dataFromServer,
    apiPathCrop,
    isArray,
    doesRefetch,
    isUploading = false,
    screen_code,
    asyncValidateData,
    reloadTabs = {},
    useNavigation,
    withoutId = false,
    resetData = false,
    apiPathFormModalWithoutId,
    setDoesRefetch = () => {},
}) => {
    const { id, modalUrl = null } = openModal;
    const api = useAPI();
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleData = async () => {
        setIsLoading(true);
        if (withoutId) {
            await api
                .get(`${apiPathFormModal}`, screen_code)
                .then((response) => {
                    setData(response?.payload);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.warn(error);
                    setIsLoading(false);
                });
        } else {
            await api
                .get(`${apiPathFormModal}/${id}${queryString}`, screen_code)
                .then((response) => {
                    setData(response?.payload);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.warn(error);
                    setIsLoading(false);
                });
        }
    };

    const saveData = async (data) => {
        setIsLoading(true);

        if (!withoutSetterFunction) {
            api.post(`${apiPathFormModalWithoutId ?? apiPathFormModal}`, { ...data, ...initialData }, screen_code)
                .then((response) => {
                    if (reloadTabs?.enabled) {
                        reloadTabs?.fnc();
                    }
                    setData(response?.payload);
                    toast.success(`Uspešno`);
                    setOpenModal({ ...openModal, show: false });
                    setIsLoading(false);
                    setDoesRefetch(true);
                    if (useNavigation?.enabled && useNavigation?.basePath) {
                        navigate(useNavigation?.basePath + response?.payload?.id);
                    }
                })
                .catch((error) => {
                    toast.warning(error.response.data.message || "Greška");
                    if (useNavigation?.enabled && error?.response?.data?.code === 409) {
                        navigate(`/portal-customers/contracts/${error?.response?.data?.payload?.id}`);
                    }
                    setData({ ...data, ...initialData });
                    setIsLoading(false);
                });
        } else {
            api.post(`${apiPathFormModalWithoutId ?? apiPathFormModal}`, { ...data, ...initialData }, screen_code)
                .then((response) => {
                    toast.success(`Uspešno`);
                    if (reloadTabs?.enabled) {
                        reloadTabs?.fnc();
                    }
                    setOpenModal({ ...openModal, show: false });
                    setIsLoading(false);
                    setDoesRefetch(true);
                    if (useNavigation?.enabled && useNavigation?.basePath) {
                        navigate(useNavigation?.basePath + response?.payload?.id);
                    }
                })
                .catch((error) => {
                    toast.warning(error.response.data.message || "Greška");
                    if (useNavigation?.enabled && error?.response?.data?.code === 409) {
                        navigate(`/portal-customers/contracts/${error?.response?.data?.payload?.id}`);
                    }
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (openModal.show || resetData) {
            handleData();
        }
    }, [openModal.show, resetData]);

    return (
        <ListPageModalWrapper
            anchor={anchor}
            open={openModal.show ?? false}
            onClose={() => setOpenModal({ ...openModal, show: false })}
            sx={sx}
            variant={variant}
            onCloseButtonClick={() => setOpenModal({ ...openModal, show: false })}
        >
            {!isLoading ? (
                children || (
                    <FormWrapper title={customTitle ? customTitle : data?.id == null ? "Novi unos" : data?.name}>
                        {shortText ? (
                            <Typography variant="body2" sx={{ marginBottom: "0.8rem" }}>
                                {shortText}
                            </Typography>
                        ) : null}
                        <Form
                            formFields={formFields}
                            initialData={data}
                            onSubmit={saveData}
                            label={label}
                            cancelButton={cancelButton}
                            submitButton={submitButton}
                            closeButton={closeButtonModalForm}
                            onCancel={() => setOpenModal({ ...openModal, show: false })}
                            styleCheckbox={styleCheckbox}
                            validateData={validateData}
                            asyncValidateData={asyncValidateData}
                            onCloseModalButton={() => {
                                setOpenModal({ show: false });
                            }}
                            apiPathCrop={apiPathCrop}
                            allowedFileTypes={allowedFileTypes}
                            onFilePicked={onFilePicked}
                            selectedFile={selectedFile}
                            handleRemoveFile={handleRemoveFile}
                            dataFromServer={dataFromServer}
                            setPropName={setPropName}
                            isArray={isArray}
                            isUploading={isUploading}
                            screen_code={screen_code}
                        />
                    </FormWrapper>
                )
            ) : (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <CircularProgress size="2rem" sx={{ marginTop: "50vh" }} />
                </Box>
            )}
        </ListPageModalWrapper>
    );
};

export default ModalForm;
