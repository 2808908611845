import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Form from "../../../../../components/shared/Form/Form";
import { useMutation, useQuery } from "react-query";
import { useEffect, useState } from "react";
import formFields from "./fields.json";
import formFields2 from "./fieldspersonal.json";
import useAPI from "../../../../../api/api";
import Button from "../../../../../components/shared/Button/Button";
import { toast } from "react-toastify";
import { InputText } from "../../../../../components/shared/Form/FormInputs/FormInputs";
import { useNavigate } from "react-router-dom";

export const Basic = ({ rid, type }) => {
    const api = useAPI();
    const [data, setData] = useState();
    const navigate = useNavigate();
    const [fields, setFields] = useState(formFields);
    const [fieldsPersonal, setFieldsPersonal] = useState(formFields2);
    const [showNote, setShowNote] = useState(false);
    const { refetch } = useQuery({
        queryKey: ["companyDataInfo", data?.firma_id],
        queryFn: async () => {
            if (data?.firma_id) {
                return await api
                    .get(`admin/registration/main/company/${data?.firma_id}`, "REGISTRATIONS_MAIN_DETAILS")
                    .then((res) => {
                        setData({
                            ...data,
                            name: res?.payload?.naziv,
                            country: res?.payload?.country,
                            id: data?.id,
                            country_id: data?.country_id,
                            firma_id: data?.firma_id,
                            pib: res?.payload?.pib,
                            maticni_broj: res?.payload?.maticni_broj,
                            city: res?.payload?.mesto,
                            address: res?.payload?.adresa,
                            postal_code: data?.postal_code,
                            slug: data?.slug,
                        });
                    })
                    ?.catch((err) => err);
            }
        },
        refetchOnWindowFocus: false,
    });

    const formatFormFields = (data, fields) => {
        if (data?.firma_id) {
            const fields_tmp = fields?.map((item) => {
                if (item?.prop_name === "firma_id") {
                    return {
                        ...item,
                        disabled: true,
                        editable: false,
                    };
                }
                return item;
            });
            setFields(fields_tmp);
        }
    };

    useEffect(() => {
        refetch();
    }, [data?.firma_id]);

    const { data: initialData, isLoading } = useQuery(
        ["registrationDetails"],
        async () => {
            return await api.get(`admin/registration/${type}/${rid}`, "REGISTRATIONS_MAIN_DETAILS").then((res) => {
                setData(res?.payload);
            });
        },
        { refetchOnWindowFocus: false }
    );

    const { mutate: reject, isLoading: isRejecting } = useMutation(["rejectAccount"], async () => {
        if (data?.note?.length > 0) {
            return await api
                .post(
                    `admin/registration/${type}/reject`,
                    {
                        id: rid,
                        note: data?.note,
                    },
                    "REGISTRATIONS_MAIN_DETAILS"
                )
                .then((res) => {
                    toast.success(`Uspešno ste odbacili zahtev!`);
                    navigate(`/registrations`);
                })
                ?.catch((err) => {
                    toast.error(err?.response?.data?.message ?? "Greška");
                });
        }
        toast.error("Morate uneti razlog odbacivanja!");
    });

    const { mutate: accept, isLoading: isAccepting } = useMutation(["acceptAccount"], async () => {
        return await api
            .post(
                `admin/registration/${type}/confirm`,
                {
                    ...data,
                    id: rid,
                },
                "REGISTRATIONS_MAIN_DETAILS"
            )
            .then((res) => {
                toast.success(`Uspešno ste prihvatili zahtev!`);
                navigate(`/registrations`);
            })
            ?.catch((err) => {
                toast.error(err?.response?.data?.message ?? "Greška");
            });
    });

    const onChange = (data) => {
        setData(data);
    };

    useEffect(() => {
        if (data) {
            formatFormFields(data, fields);
        }
    }, [data]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: "2rem",
                    flexWrap: "wrap",
                }}
            >
                <Box sx={{ flex: "1" }}>
                    <Typography variant={`h6`} sx={{ paddingBottom: "1rem" }}>
                        Informacije o kompaniji
                    </Typography>
                    <Form
                        asyncValidateData={(data) => {
                            setData(data);
                            return data;
                        }}
                        screen_code={`REGISTRATIONS_MAIN_DETAILS`}
                        formFields={fields}
                        initialData={data}
                        submitButton={false}
                        label={`Podaci o kompaniji`}
                        onChange={onChange}
                    />
                </Box>

                <Box sx={{ flex: "1" }}>
                    <Typography variant={`h6`} sx={{ paddingBottom: "1rem" }}>
                        Informacije o predstavniku
                    </Typography>

                    <Form formFields={fieldsPersonal} initialData={data} submitButton={false} onChange={onChange} />
                </Box>
            </Box>
            <Box>
                {(showNote || data?.note?.length > 0) && (
                    <InputText name={`note`} label={`Razlog odbijanja`} value={data?.note} onChange={(e) => setData({ ...data, note: e.target.value })} required />
                )}
                {type === "main" && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "1rem",
                            marginTop: "1rem",
                        }}
                    >
                        <Button
                            variant={`outlined`}
                            label={showNote ? `Potvrdi odbacivanje` : `Odbaci nalog`}
                            disabled={isRejecting}
                            onClick={() => {
                                if (showNote) {
                                    reject();
                                } else {
                                    setShowNote(true);
                                }
                            }}
                            icon={`close`}
                        ></Button>
                        <Button variant={`contained`} label={`Prihvati nalog`} disabled={isAccepting} onClick={accept} icon={`check`}></Button>
                    </Box>
                )}
            </Box>
        </>
    );
};
