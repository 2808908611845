import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "../../../api/api";
import SearchableListForm from "../../../components/shared/Form/SearchableListForm/SearchableListForm";
import Loading from "../../../components/shared/Loading/Loading";

const PackagesPanel = ({ pacId, screen_code }) => {
    const [listData, setListData] = useState([]);
    const [isLoading, setIsLoading] = useState([]);

    const api = useAPI();
    const apiPath = "admin/packages/packages-items";
    const handleList = () => {
        setIsLoading(true);
        api.get(`${apiPath}/${pacId}`, screen_code)
            .then((response) => {
                setListData(response?.payload);
                setIsLoading(false);
            })
            .catch((error) => {
                console.warn(error);
                setIsLoading(false);
            });
    };

    const handleSubmit = (data) => {
        api.post(apiPath, { packages_id: pacId, packages_items_ids: data }, screen_code)
            .then((response) => {
                toast.success("Uspešno");
            })
            .catch((error) => {
                console.warn(error);
                toast.warn("Greška");
            });
    };

    useEffect(() => {
        handleList();
    }, []);

    return !isLoading ? <SearchableListForm available={listData.available} selected={listData.selected} onSubmit={handleSubmit} /> : <Loading />;
};

export default PackagesPanel;
