import ListPage from "../../../../../../components/shared/ListPage/ListPage";
import fields from "../fields.json";
export const ActiveContracts = ({ rid, cid, type }) => {
    return (
        <ListPage
            listPageId={`active-contracts-registration`}
            apiUrl={`admin/registration/${type}/active/contracts`}
            company_id={cid}
            columnFields={fields}
            title={` `}
            screen_code={`REGISTRATIONS_MAIN_DETAILS`}
            showNewButton={false}
        />
    );
};
