import ListPage from "../../components/shared/ListPage/ListPage";

import tblFields from "./tblFields.json";
import formFieldsPersonal from "./formFieldsPersonal.json";
import formFieldsBusiness from "./formFieldsBusiness.json";
import useAPI from "../../api/api";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteDialog from "../../components/shared/Dialogs/DeleteDialog";
import { useQuery } from "react-query";

const PortalCustomers = () => {
    const api = useAPI();
    const { data } = useQuery({
        queryKey: ["is_admin"],
        queryFn: async () => {
            return await api.get(`/admin/users/is-admin`, "USERS")?.then((res) => res?.payload);
        },
    });
    const [formFields, setFormFields] = useState([]);
    const [formType, setFormType] = useState("personally");
    const [editUrl, setEditUrl] = useState(null);
    const [doesRefetch, setDoesRefetch] = useState(false);
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        id: "",
        data: [],
        main_line: "",
    });

    const disableClientId = (id, fields) => {
        const updatedFields = fields?.map((field) => {
            if (field?.prop_name === "client_id" && id) {
                return {
                    ...field,
                    disabled: true,
                    editable: false,
                };
            }
            if (field?.prop_name === "opening_date" && id) {
                return {
                    ...field,
                    disabled: true,
                    editable: true,
                };
            }

            if (!data?.is_admin) {
                if (field?.prop_name === "status") {
                    return {
                        ...field,
                        disabled: true,
                    };
                }
            }

            return {
                ...field,
            };
        });

        setFormFields(updatedFields);
    };

    const customActions = {
        edit: {
            icon: "edit",
            position: 1,
            clickHandler: {
                type: "modal_form",
                fnc: (rowData) => {
                    switch (rowData.customer_type) {
                        case "personally":
                            if (!data?.is_admin) {
                                const newFields = formFieldsPersonal.map((field) => {
                                    if (field.prop_name === "status") {
                                        return {
                                            ...field,
                                            disabled: true,
                                        };
                                    }
                                    return {
                                        ...field,
                                    };
                                });
                                setFormFields(newFields);
                            } else {
                                setFormFields(formFieldsPersonal);
                            }
                            setFormType("personally");
                            disableClientId(rowData?.id, formFieldsPersonal);
                            return {
                                show: true,
                                id: rowData.id,
                            };
                        default:
                            if (!data?.is_admin) {
                                const newFields = formFieldsBusiness.map((field) => {
                                    if (field.prop_name === "status") {
                                        return {
                                            ...field,
                                            disabled: true,
                                        };
                                    }
                                    return {
                                        ...field,
                                    };
                                });
                                setFormFields(newFields);
                            } else {
                                setFormFields(formFieldsBusiness);
                            }
                            setFormType("business");
                            disableClientId(rowData?.id, formFieldsBusiness);
                            return {
                                show: true,
                                id: rowData.id,
                            };
                    }
                },
            },
        },

        contracts: {
            label: "Ugovori",
            title: "Ugovori",
            icon: "description",
            position: 2,
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/portal-customers/contracts/${rowData.id}`;
                },
            },
        },

        delete: {
            position: 3,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    api.get(`admin/portal-customers/list/message/${rowData.id}`, "CLIENTS_DELETE")
                        .then((res) => {
                            setDeleteModal({
                                show: true,
                                id: rowData.id,
                                ...res?.payload,
                            });
                        })
                        .catch(() => toast.warning("Došlo je do greške"));
                },
            },
        },
    };

    const buttons = [
        {
            label: "Fizičko lice",
            clickHandler: {
                type: "modal_form",
                fnc: (rowData) => {
                    setFormFields(formFieldsPersonal);
                    setFormType("personally");
                    return {
                        show: true,
                        id: "new",
                    };
                },
            },
        },
        {
            label: "Pravno lice",
            clickHandler: {
                type: "modal_form",
                fnc: (rowData) => {
                    setFormFields(formFieldsBusiness);
                    setFormType("business");
                    return {
                        show: true,
                        id: "new",
                    };
                },
            },
        },
    ];

    const asyncValidateDataClientID = async (client_id_field, data) => {
        let res;
        let path = `${client_id_field?.fillFromApi}?search_id=${data.client_id}`;
        await api
            .get(path, "CLIENTS_NEW")
            .then((response) => {
                res = response?.payload[0];
            })
            .catch((error) => {
                console.warn(error);
            });
        return res;
    };

    const asyncValidateData = async (data, field) => {
        let ret = data;
        switch (field) {
            case "client_id":
                let index = formFields.findIndex((it) => {
                    return it.prop_name === "client_id";
                });
                let client_id_field = formFields[index];

                if (formType === "personally") {
                    if (data.client_id) {
                        let row = await asyncValidateDataClientID(client_id_field, data);

                        ret.name = row?.name;
                        ret.jmbg = row?.jmbg;
                        ret.country_name = row?.country_name;
                    } else {
                        ret.name = "";
                        ret.jmbg = "";
                        ret.country_name = "";
                    }
                } else {
                    if (data.client_id) {
                        let row = await asyncValidateDataClientID(client_id_field, data);

                        ret.name = row?.name;
                        ret.pib = row?.pib;
                        ret.maticni_broj = row?.maticniBroj;
                    } else {
                        ret.name = "";
                        ret.pib = "";
                        ret.maticni_broj = "";
                    }
                }
                return ret;
            default:
                return ret;
        }
    };

    useEffect(() => {
        setEditUrl(`admin/portal-customers/basic-data/${formType}`);
    }, [formType]);

    const handleDeleteAfterMesage = () => {
        api.delete(`admin/portal-customers/list/confirm/${deleteModal.id}`, "CLIENTS_DELETE")
            .then((res) => {
                toast.success("Uspešno obrisano");
                setDeleteModal({
                    show: false,
                    id: "",
                    data: [],
                    main_line: "",
                });
                setDoesRefetch(!doesRefetch);
            })
            .catch((err) => toast.warning(err?.response?.data?.message ?? err?.response?.data?.payload?.message ?? "Došlo je do greške"));
    };

    return (
        <>
            <ListPage
                // validateData={validateData}
                asyncValidateData={asyncValidateData}
                listPageId="PortalCustomers"
                apiUrl="admin/portal-customers/list/list"
                title="Portal korisnici"
                columnFields={tblFields}
                useColumnFields={false}
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
                additionalButtons={buttons}
                showNewButton={false}
                formFields={formFields}
                customActions={customActions}
                screen_code={editUrl ? "CLIENTS_POST" : "CLIENTS_LIST"}
                editUrl={editUrl}
                useNavigation={{
                    enabled: true,
                }}
            />
            <DeleteDialog
                title={deleteModal?.portal_customer_line ?? ""}
                screen_code={"CLIENTS_DELETE"}
                handleConfirm={handleDeleteAfterMesage}
                description={deleteModal?.main_line ?? ""}
                openDeleteDialog={deleteModal}
                setOpenDeleteDialog={setDeleteModal}
                nameOfButton={deleteModal?.portal_customer_checkbox ?? "Obriši"}
            />
        </>
    );
};

export default PortalCustomers;
