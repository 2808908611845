import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import formFields from "./formField.json";
import { toast } from "react-toastify";
import Form from "../../../components/shared/Form/Form";
import useAPI from "../../../api/api";
import DetailsPage from "../../../components/shared/ListPage/DetailsPage/DetailsPage";
import IconList from "../../../helpers/icons";
import PackagesPanel from "./PackagesPanel";

const PackagesDetails = () => {
    const { pacId } = useParams();
    const api = useAPI();
    const init = {
        id: null,
        screen: null,
        name: null,
    };
    const apiPath = "admin/packages/basic-data";
    const [data, setData] = useState(init);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const screen_code = "PACKAGES";
    const handleData = async () => {
        setIsLoading(true);
        await api
            .get(`${apiPath}/${pacId}`, "SCORING_PACKAGES_DETAILS")
            .then((response) => {
                setData(response?.payload);
                setIsLoading(false);
            })
            .catch((error) => {
                console.warn(error);
                setIsLoading(false);
            });
    };

    const saveData = async (data) => {
        let oldId = data.id;
        api.post(apiPath, data, data?.id ? "SCORING_PACKAGES_UPDATE" : "SCORING_PACKAGES_NEW")
            .then((response) => {
                setData(response?.payload);
                toast.success(`Uspešno`);

                if (oldId === null) {
                    let tId = response?.payload?.id;
                    navigate(`/packages/${tId}`, { replace: true });
                }
            })
            .catch((error) => {
                console.warn(error);
                toast.warning("Greška");
            });
    };

    useEffect(() => {
        handleData();
    }, []);

    const fields = [
        {
            name: "Osnovno",
            icon: IconList.dataThresholding,
            enabled: true,
            component: <Form formFields={formFields} initialData={data} onSubmit={saveData} screen_code={"SCORING_PACKAGES_DETAILS"} />,
        },
        {
            name: "Funkcije",
            icon: IconList.screenShare,
            enabled: data?.id,
            component: <PackagesPanel pacId={data?.id} screen_code={"PACKAGES_DETAILS"} />,
        },
    ];

    return <DetailsPage title={data?.id == null ? "Unos novog paketa" : data?.name} fields={fields} ready={!isLoading} screen_code={screen_code} />;
};

export default PackagesDetails;
