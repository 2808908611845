import IconList from "../helpers/icons";
import { makeScreen, MenuGroup } from "./utils";

import Packages from "../pages/Packages/Packages";
import PackagesDetails from "../pages/Packages/PackagesDetails/PackagesDetails";
import RolesListPage from "../pages/Roles/RolesListPage";
import RolesDetailsPage from "../pages/Roles/Details/RolesDetailsPage";
import Users from "../pages/Users/Users";
import UsersDetils from "../pages/Users/Details/UsersDetails";
import PortalCustomers from "../pages/PortalCustomers/PortalCustomers";
import Contracts from "../pages/Contracts/PortalContracts";
import ContractDetails from "../pages/Contracts/ContractDetails/ContractDetails";
import Accounts from "../pages/Accounts/Accounts";
import AccountDetails from "../pages/Accounts/AccountDetails/AccountDetails";
import AllContracts from "../pages/Contracts/AllContracts/AllContracts";
import ConfirmContract from "../pages/Contracts/AllContracts/ConfirmContract/ConfirmContract";
import ExpiredContracts from "../pages/Contracts/AllContracts/ExpiredContracts/ExpiredContracts";
import ToBeExpired from "../pages/Contracts/AllContracts/ToBeExpired/ToBeExpired";
import ChangesOnContracts from "../pages/Contracts/AllContracts/ChangesOnContracts/ChangesOnContracts";
import DetailChanges from "../pages/Contracts/AllContracts/ChangesOnContracts/DetailChanges/DetailChanges";
import { AllRegistrations } from "../pages/Registrations/AllRegistrations";
import { NewRegistrations } from "../pages/Registrations/NewRegistrations";
import { AcceptedRegistrations } from "../pages/Registrations/AcceptedRegistrations";
import { RejectedRegistrations } from "../pages/Registrations/RejectedRegistrations";
import { IncompleteRegistrations } from "../pages/Registrations/IncompleteRegistrations";
import { RegistrationDetails } from "../pages/Registrations/RegistrationDetails/RegistrationDetails";
import { NewRegistration } from "../pages/Registrations/NewRegistration";
import { Translations } from "../pages/Translations/translations";
import News from "../pages/News/News";
import NewsCategoryList from "../pages/NewsCategoryList/NewsCategoryList";
import NewsDetails from "../pages/News/NewsDetails/NewsDetails";
import NewsCategoryListDetails from "../pages/NewsCategoryList/NewsCategoryListDetails/NewsCategoryListDetails";

/** The list of available screens. */
const { SETTINGS, PORTAL } = MenuGroup;
const screens = {
    ROLES: ["/roles", "Uloge", IconList.reduceCapacity, SETTINGS, RolesListPage, [[":roleId", RolesDetailsPage]]],
    USERS: ["/users", "Korisnici", IconList.group, SETTINGS, Users, [[":userId", UsersDetils]]],
    PACKAGES: ["/packages", "Scoring paketi", IconList.inventory, SETTINGS, Packages, [[":pacId", PackagesDetails]]],
    PORTAL_CUSTOMERS: ["/portal-customers", "Korisnici", IconList.group, PORTAL, PortalCustomers, []],
    PORTAL_CONTRACTS: [
        "/portal-customers/contracts",
        "Ugovori",
        IconList.description,
        PORTAL,
        AllContracts,
        [
            [":customerId", Contracts],
            [":customerId/:contractId", ContractDetails],
        ],
        true,
    ],
    NEWS: [
        "/news",
        "Vesti",
        IconList.newspaper,
        PORTAL,
        News,
        [
            [":nid", NewsDetails],
            ["category", NewsCategoryList],
            ["category/:cid", NewsCategoryListDetails],
        ],
    ],
    PORTAL_CONTRACTS_MAIN_LIST: ["/contracts", "Ugovori", IconList.description, PORTAL, AllContracts, []],
    PORTAL_CONTRACTS_CONFIRM_LIST: ["/contracts/confirm", "Potvrda ugovora", IconList.description, PORTAL, ConfirmContract, [], true],
    PORTAL_CONTRACTS_EXPIRED_LIST: ["/contracts/expired", "Istekli ugovori", IconList.description, PORTAL, ExpiredContracts, [], true],
    PORTAL_CONTRACTS_CLOSE_TO_EXPIRE_LIST: ["/contracts/to-be-expired", "Uskoro ističu", IconList.description, PORTAL, ToBeExpired, [], true],
    PORTAL_CONTRACTS_EDITS_LIST: ["/contracts/changes", "Izmene na ugovorima", IconList.description, PORTAL, ChangesOnContracts, [[":cId", DetailChanges]], true],
    REGISTRATIONS_MAIN_LIST: ["/registrations", "Registracije", IconList.group, PORTAL, AllRegistrations, [[":type/:rid/:cid", RegistrationDetails]]],
    REGISTRATIONS_ACCEPTED_LIST: ["/registrations/accepted", "Registracije", IconList.group, PORTAL, AcceptedRegistrations, [], true],
    REGISTRATIONS_MAIN_NEW: ["/registrations/new", "Registracije", IconList.group, PORTAL, NewRegistration, [], true],
    REGISTRATIONS_REJECTED_LIST: ["/registrations/rejected", "Registracije", IconList.group, PORTAL, RejectedRegistrations, [], true],
    REGISTRATIONS_INCOMPLETE_LIST: ["/registrations/incomplete", "Registracije", IconList.group, PORTAL, IncompleteRegistrations, [], true],
    ACCOUNTS: ["/accounts/:clientId/:contractId", "Nalozi", IconList.group, PORTAL, Accounts, [[":accId", AccountDetails]], true],
    TRANSLATIONS: ["/translations", "Prevodi", IconList.translate, SETTINGS, Translations, []],
};

/**
 * Builds the screens from the configuration above.
 *
 * @typedef AvailableScreen
 *    @property {string} name
 *    @property {string} path
 *    @property {JSX.Element} icon
 *    @property {string} group
 *    @property {JSX.Element} component
 *    @property {AvailableScreen[]} children
 */
export const availableScreens = {};
for (const code in screens) {
    availableScreens[code] = makeScreen(screens[code]);
}
