import { useState } from "react";

import ChangePasswordDialog from "../../components/shared/ChangePasswordDialog/ChangePasswordDialog";
import ListPage from "../../components/shared/ListPage/ListPage";
import formFields from "./Details/forms/formField.json";
import tblFields from "./tblFields.json";
import useAPI from "../../api/api";
import { toast } from "react-toastify";

const Users = () => {
    const [openDialog, setOpenDialog] = useState({ show: false, userId: null });
    const screen_code = "USERS";
    const [formFields, setFormFields] = useState(tblFields);
    const api = useAPI();

    const customActions = {
        key: {
            type: "custom",
            display: true,
            position: 2,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    return setOpenDialog({ show: true, userId: rowData.id });
                },
            },
            icon: "key",
            title: "Promeni lozinku",
        },
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
            deleteClickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    api.delete(`admin/users/${rowData.id}`, screen_code)
                        .then(() => toast.success("Zapis je uspešno obrisan"))
                        .catch(() => toast.warning("Došlo je do greške prilikom brisanja"));

                    return {
                        show: false,
                        id: rowData.id,
                        mutate: 1,
                    };
                },
            },
        },
    };

    return (
        <>
            <ListPage
                screen_code={screen_code}
                apiUrl="admin/users/list"
                editUrl="admin/users"
                title="Korisnici"
                useColumnFields={false}
                formFields={formFields}
                columnFields={tblFields}
                actionNewButton="modal"
                customActions={customActions}
            />
            <ChangePasswordDialog screen_code={screen_code} openDialog={openDialog} setOpenDialog={setOpenDialog} apiPath="admin/users/reset-password" />
        </>
    );
};

export default Users;
