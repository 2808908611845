import { toast } from "react-toastify";
import ListPage from "../../components/shared/ListPage/ListPage";
import tblFields from "./tblFields.json";
import useAPI from "../../api/api";
import ModalContent from "./ModalContent";

const Packages = () => {
    const api = useAPI();
    const screen_code = "PACKAGES";
    const customActions = {
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData, handleDeleteModalData) => {
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                        children: <ModalContent screen_code={"SCORING_PACKAGES_REMOVE"} apiPath={`admin/packages/list/message/${rowData.id}`} rowData={rowData} handleDeleteModalData={handleDeleteModalData} />,
                    };
                },
            },
            deleteClickHandler: {
                type: "dialog_delete",
                fnc: (rowData, deleteModalData) => {
                    api.delete(`admin/packages/list/confirm/${rowData.id}`, "SCORING_PACKAGES_REMOVE")
                        .then(() => toast.success("Zapis je uspešno obrisan"))
                        .catch(() => toast.warning("Došlo je do greške prilikom brisanja"));

                    return {
                        show: false,
                        id: rowData.id,
                        mutate: 1,
                    };
                },
            },
        },
    };

    return <ListPage screen_code={"SCORING_PACKAGES_LIST"} apiUrl="admin/packages/list/list" title="Scoring paketi" columnFields={tblFields} customActions={customActions} />;
};

export default Packages;
