import React, { useState } from "react";

import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";

import ListPagination from "./ListPagination/ListPagination";
import ListTableBody from "./ListTableBody";
import ListTableHead from "./ListTableHead";

import styles from "./ListTable.module.scss";

const ListTable = ({
    fields = [],
    listData = [],
    isLoading = false,
    onPageChange,
    handleOnClickActions,
    previewColumn,
    showAddButtonTableRow,
    tooltipAddButtonTableRow,
    customActions,
    tableCellActions,
    doesRefetch,
    screen_code,
    system,
}) => {
    const { items, pagination } = listData;
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");

    const handleSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    // Show the table
    return (
        <>
            <TableContainer className={styles.wrapper}>
                <Table>
                    <ListTableHead fields={fields} order={order} orderBy={orderBy} onRequestSort={handleSort} rowCount={fields.length} />

                    <ListTableBody
                        tableCellActions={tableCellActions}
                        items={items ?? []}
                        doesRefetch={doesRefetch}
                        fields={fields}
                        isLoading={isLoading}
                        screen_code={screen_code}
                        handleOnClickActions={handleOnClickActions}
                        error={null}
                        system={system}
                        previewColumn={previewColumn}
                        showAddButtonTableRow={showAddButtonTableRow}
                        tooltipAddButtonTableRow={tooltipAddButtonTableRow}
                        customActions={customActions}
                    />
                </Table>
            </TableContainer>

            <ListPagination pagination={pagination} onPageChange={onPageChange} />
        </>
    );
};

export default ListTable;
