import { useEffect, useState } from "react";
import ListPage from "../../../components/shared/ListPage/ListPage";
import fields from "./fields.json";
import { useQuery } from "react-query";
import useAPI from "../../../api/api";
import Button from "../../../components/shared/Button/Button";
import IconList from "../../../helpers/icons";
import { useNavigate, useSearchParams } from "react-router-dom";

const Contacts = ({ client_id, screen_code, contractId, reloadTabs }) => {
    const api = useAPI();
    const init = {
        client_id: client_id,
        contracts_id: contractId,
    };
    const [data, setData] = useState(init);
    const [doesRefetch, setDoesRefetch] = useState(false);
    const [columnFields, setColumnFields] = useState(fields);
    const [formFields, setFormFields] = useState(fields);
    const [rewrite, setRewrite] = useState(true);
    const [openModal, setOpenModal] = useState({
        open: false,
        id: null,
        type: "",
    });

    const customActions = {
        edit: {
            display: true,
            position: 1,
            clickHandler: {
                type: "modal_form",
                fnc: (rowData) => {
                    setRewrite(!rewrite);
                    setOpenModal({
                        open: true,
                        id: rowData?.id,
                        type: "edit",
                    });
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
            title: "Izmeni",
        },
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    setOpenModal({
                        open: true,
                        id: rowData?.id,
                        type: "delete",
                    });
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
        },
    };

    const formatFormFields = (data) => {
        let arr = [];
        data.map((item) => {
            if (item?.prop_name === "user_id") {
                item.usePropName = false;
                item.fillFromApi = item.fillFromApi + "?contracts_id=" + contractId;
            }
        });
        setFormFields([...data]);
    };

    useEffect(() => {
        formatFormFields(formFields);
    }, []);

    const navigate = useNavigate();
    const navigateToNextTab = () => {
        navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=limits`, { replace: true });
    };
    const [params] = useSearchParams();
    const showConfirm = params.get("confirm");

    const handleScreenCode = (openModal) => {
        let code = "";
        switch (true) {
            case openModal?.id && openModal?.type === "edit":
                code = "ADVISORS_UPDATE";
                break;
            case openModal?.id && openModal?.type === "delete":
                code = "ADVISORS_DELETE";
                break;
            case !openModal?.id && !openModal?.type:
                code = "ADVISORS_LIST";
                break;
            case !openModal?.id && openModal?.type === "edit":
                code = "ADVISORS_NEW";
                break;
            default:
                code = "ADVISORS_LIST";
                break;
        }
        return code;
    };

    const formatFields = (data) => {
        const objectKeys = Object.keys(data);

        let temp_data = formFields?.map((item) => {
            if (objectKeys?.includes(item?.prop_name)) {
                return {
                    ...item,
                    description: `Za ovo polje je zatražena izmena. Zatražena nova vrednost: ${data[item?.prop_name]}`,
                };
            } else {
                return item;
            }
        });
        setFormFields(temp_data);
    };

    const {
        data: contract_changes,
        isLoading,
        isSuccess,
    } = useQuery(
        ["contractsAdvisorsChanges", contractId, rewrite, doesRefetch],
        async () => {
            return await api.get(`admin/contracts/advisors/basic-data/edited-fields/${contractId}?advisor_id=${openModal?.id}`, "CONTRACTS_DETAILS").then((res) => {
                formatFields(res?.payload);
                return res?.payload;
            });
        },
        {
            enabled: openModal?.open,
        },
    );

    const { data: isEnabled, isLoading: isLoadingPermission } = useQuery(
        ["isAllowedToAddAdvisor", contractId],
        async () => {
            return await api.get(`admin/contracts/advisors/basic-data/consultant/permissions/${contractId}`, "CONTRACTS_DETAILS").then((res) => res?.payload);
        },
        {},
    );

    const { data: is_consultant } = useQuery({
        queryKey: ["is_consultant"],
        queryFn: async () => {
            return await api.get("/admin/users/is-consultant", "USERS").then((res) => {
                if (res) {
                    return res?.payload?.is_consultant;
                }
            });
        },
    });

    return (
        <>
            {showConfirm === "true" && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                        margin: "0 0 2rem 2rem",
                    }}
                >
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=contacts&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowBack}
                        text="Nazad"
                        label={`Prethodno`}
                    />
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${client_id}/${contractId}?tab=comments&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowForward}
                        text="Napred"
                        label={`Sledeće`}
                    />
                </div>
            )}
            {!isLoadingPermission && (
                <ListPage
                    listPageId={`Advisors${contractId}`}
                    screen_code={handleScreenCode(openModal)}
                    apiUrl={`admin/contracts/advisors/list/list/${contractId}`}
                    editUrl={`admin/contracts/advisors/basic-data`}
                    title="Savetnici"
                    deleteUrl={`admin/contracts/advisors/list`}
                    client_id={client_id}
                    reloadTabs={{
                        enabled: true,
                        fnc: reloadTabs,
                    }}
                    doesRefetch={doesRefetch}
                    setDoesRefetch={setDoesRefetch}
                    initialData={data}
                    useColumnFields={false}
                    showNewButton={isEnabled?.enabled}
                    formFields={formFields}
                    editUrlQueryString={`?contracts_id=${contractId}`}
                    columnFields={columnFields}
                    actionNewButton="modal"
                    setModalData={setOpenModal}
                    customActions={customActions}
                />
            )}
        </>
    );
};

export default Contacts;
