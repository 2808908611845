import tableFields from "../forms/seo.json";
import { useEffect, useState } from "react";
import ListPage from "../../../../components/shared/ListPage/ListPage";
import useAPI from "../../../../api/api";

const Seo = ({ categoryId }) => {
    const api = useAPI();
    const [formFieldsTemp, setFormFieldsTemp] = useState(tableFields);

    const handleInformationImage = () => {
        api.get(`admin/news/category/seo/options/upload`, "CATEGORY_NEWS_GET")
            .then((response) => {
                formatFormFields(response?.payload);
            })
            .catch((error) => console.warn(error));
    };

    const formatFormFields = (data) => {
        if (data) {
            const { allow_size, allow_format, image } = data;
            let arr = formFieldsTemp.map((field) => {
                if (field?.prop_name === "social_share_image") {
                    const descripiton = `Veličina fajla ne sme biti veća od ${allow_size / (1024 * 1024).toFixed(2)}MB. Dozvoljeni formati fajla: ${allow_format
                        .map((format) => format.name)
                        .join(", ")}. ${field?.description}`;

                    return {
                        ...field,
                        description: descripiton,
                        validate: {
                            imageUpload: data,
                        },
                        ui_prop: {
                            fileUpload: data,
                        },
                        dimensions: { width: image?.width, height: image?.height },
                    };
                } else {
                    return {
                        ...field,
                    };
                }
            });
            setFormFieldsTemp([...arr]);
        }
    };

    useEffect(() => {
        handleInformationImage();
    }, []);
    return (
        <ListPage
            listPageId="NewsCategoriesSeo"
            apiUrl={`admin/news/category/seo/${categoryId}`}
            editUrl={`admin/news/category/seo`}
            deleteUrl={`admin/news/category/seo`}
            apiPathCrop={`admin/news/category/seo/options/crop`}
            title=" "
            columnFields={formFieldsTemp}
            actionNewButton="modal"
            initialData={{ id_category_news: categoryId }}
            addFieldLabel="Dodajte novu vrednost"
            showAddButton={true}
            screen_code="CATEGORY_NEWS_LIST"
        />
    );
};

export default Seo;
