import tblFields from "./tblFields.json";
import { useQuery } from "react-query";
import useAPI from "../../../../api/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ListPage from "../../../../components/shared/ListPage/ListPage";

const ConfirmContract = () => {
    const api = useAPI();
    const navigate = useNavigate();

    const [formFields, setFormFields] = useState(tblFields);

    const actions = {
        delete: {
            type: "delete",
            display: false,
        },
        edit: {
            type: "edit",
            display: true,
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/portal-customers/contracts/${rowData?.client_id}/${rowData?.id}?tab=basic&confirm=true`;
                },
            },
        },
    };

    const buttons = [
        {
            label: "Nazad",
            icon: "arrow_back",
            clickHandler: {
                type: "",
                fnc: () => navigate(-1),
            },
        },
    ];

    return (
        <ListPage
            showNewButton={false}
            apiUrl={`admin/contracts/confirm/list`}
            customActions={actions}
            columnFields={formFields}
            additionalButtons={buttons}
            formFields={formFields}
            useColumnFields={false}
            screen_code={`PORTAL_CONTRACTS_CONFIRM_LIST`}
            title={`Potvrda ugovora`}
        />
    );
};

export default ConfirmContract;
