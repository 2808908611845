import React from "react";
import tableFields from "./newsTableFields.json";
import ListPage from "../../components/shared/ListPage/ListPage";

const News = () => {
    const buttons = [
        {
            id: 1,
            label: "Kategorije",
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/news/category`;
                },
            },
        },
    ];

    return <ListPage listPageId="News" apiUrl="admin/news/news/list" title="Vesti" columnFields={tableFields} additionalButtons={buttons} screen_code="NEWS_LIST" />;
};

export default News;
